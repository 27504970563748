import React, { useState, useEffect } from 'react';
import { auth } from './config/firebase';
import { User } from "firebase/auth";
import { XMarkIcon } from '@heroicons/react/24/outline';
import { Dialog } from '@headlessui/react';
import logo from './assets/_7afa50e7-4fe5-42b5-a782-8293c4c5172e-removebg-preview.png';
import logoText from './assets/logo_text.png'
import { Link } from 'react-router-dom';
import logoLato from './assets/logo_lato.png'


const navigation = [
    { name: 'How it works', href: '#' },
    { name: 'FAQ', href: '#' },
    { name: 'Blog', href: '#' },
    { name: 'About us', href: '#' },
];

const posts = [
    {
        id: 1,
        title: 'The advantages of hint-based interview prep',
        href: '#',
        description:
            'Investigating why  hint-based interview prep is a better way to prepare for technical interviews.',
        imageUrl:
            'https://images.unsplash.com/photo-1496128858413-b36217c2ce36?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=3603&q=80',
        date: 'Sept 17, 2024',
        datetime: '2020-03-16',
        category: { title: 'Interview Prep', href: '#' },

    },
    // More posts...
]


const Blog = () => {
    const [user, setUser] = useState<User | null>(null);
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((authUser) => {
            if (authUser) {
                setUser(authUser);
            } else {
                console.log("User is signed out");
                setUser(null);
            }
        });

        return () => unsubscribe();
    }, []);

    useEffect(() => {

        document.title = 'Terms of Service | HinterviewGPT';

    }, []);

    return (
        <div className=" bg-white">
            <header className="sticky top-0 z-50 max-w-8xl border border-gray-300 bg-white rounded-2xl mt-2 mx-auto sm:mx-2 lg:mx-4">
                    {/* Navbar */}
                    <nav className="flex items-center justify-between h-16 px-2 lg:px-4" aria-label="Global">
                        {/* Logo */}
                        <div className="flex">
                            <a href="/" className="-m-1">
                                <span className="sr-only">HinterviewGPT</span>
                                <img className="h-8 w-auto" src={logoLato} alt="HinterviewGPT Logo" />
                            </a>
                        </div>

                        {/* Navigation Links */}
                        <div className="hidden lg:flex lg:gap-x-6 ml-auto">
                            <a href="/#how-it-works" className="text-md font-medium text-gray-700 hover:text-indigo-600 transition">
                                How It Works
                            </a>
                            <a href="/#pricing" className="text-md font-medium text-gray-700 hover:text-indigo-600 transition">
                                Pricing
                            </a>
                            <a href="/#faq" className="text-md font-medium text-gray-700 hover:text-indigo-600 transition">
                                FAQ
                            </a>
                            <a href="/blog" className="text-md font-medium text-gray-700 hover:text-indigo-600 transition">
                                Blog
                            </a>
                        </div>

                        {/* Log in / App Button */}
                        <div className="hidden lg:flex lg:justify-end ml-4">
                            <a
                                href={user ? "/activity" : "/auth"}
                                className="inline-flex items-center px-3 py-1.5 border border-transparent text-sm font-semibold rounded-md text-white bg-indigo-600 hover:bg-indigo-700 transition"
                            >
                                {user ? "App" : "Log In"} <span aria-hidden="true">→</span>
                            </a>
                        </div>

                        {/* Mobile Menu Button */}
                        <div className="flex lg:hidden">
                            <button
                                type="button"
                                className="inline-flex items-center justify-center p-1.5 rounded-md text-gray-700 hover:text-indigo-600 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                                onClick={() => setMobileMenuOpen(true)}
                            >
                                <span className="sr-only">Open main menu</span>
                                {/* Icon: Hamburger Menu */}
                                {/* <MenuIcon className="h-6 w-6" aria-hidden="true" /> */}
                            </button>
                        </div>
                    </nav>
                </header>

            <main className="flex-grow p-4 lg:px-28 flex flex-col min-h-screen">
                <div className="bg-white py-24 sm:py-22">
                    <div className="mx-auto max-w-7xl px-6 lg:px-8">
                        <div className="mx-auto max-w-2xl text-center">
                            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                                From the HinterviewGPT blog
                            </h2>
                        </div>
                        <div className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-3">
                            {posts.map((post) => (
                                <article key={post.id} className="flex flex-col items-start justify-between">
                                    {/* <div className="relative w-full">
                                        <img
                                            alt=""
                                            src={post.imageUrl}
                                            className="aspect-[16/9] w-full rounded-2xl bg-gray-100 object-cover sm:aspect-[2/1] lg:aspect-[3/2]"
                                        />
                                    </div> */}
                                    <div className="max-w-xl">
                                        <div className="mt-8 flex items-center gap-x-4 text-xs">
                                            <time dateTime={post.datetime} className="text-gray-500">
                                                {post.date}
                                            </time>
                                        </div>
                                        <div className="group relative">
                                            <h3 className="mt-3 text-lg font-semibold leading-6 text-gray-900 group-hover:text-gray-600">
                                                <Link to={`/blog/${post.id}`}>
                                                    {post.title}
                                                </Link>
                                            </h3>
                                            <p className="mt-5 line-clamp-3 text-sm leading-6 text-gray-600">{post.description}</p>
                                        </div>
                                    </div>
                                </article>
                            ))}
                        </div>
                    </div>
                </div>
            </main>

            <footer className="bg-white border-t border-gray-200 mt-auto">
                <div className="mx-auto max-w-7xl py-3 px-4 sm:px-6 lg:px-8 flex justify-between items-center text-sm">
                    <p className="text-gray-500">© 2024 HinterviewGPT. All rights reserved.</p>
                    <div>
                        <a href="/privacy" className="text-indigo-600 hover:text-indigo-900 ml-4">
                            Privacy Policy
                        </a>
                        <a href="/tos" className="text-indigo-600 hover:text-indigo-900 ml-4">
                            Terms of Service
                        </a>
                    </div>
                </div>
            </footer>
        </div>

    );
};

export default Blog;
