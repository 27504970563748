import React, { Fragment } from 'react';
import { Menu, MenuButton, MenuItem as HeadlessMenuItem, MenuItems, Transition } from '@headlessui/react';
import { ChevronDownIcon, CheckIcon } from '@heroicons/react/20/solid'; // Import CheckIcon

interface LanguageSelectorProps {
  selectedLanguage: string;
  onLanguageChange: (language: string) => void;
}

const LanguageSelector: React.FC<LanguageSelectorProps> = ({ selectedLanguage, onLanguageChange }) => {
  const languages = [
    { value: 'python', label: 'Python' },
    { value: 'javascript', label: 'JavaScript' },
    { value: 'java', label: 'Java' },
    // { value: 'typescript', label: 'TypeScript' },
    { value: 'ruby', label: 'Ruby' },
    { value: 'golang', label: 'Go' },
    { value: 'scala', label: 'Scala' },
    { value: 'cpp', label: 'C/C++' },
    { value: 'csharp', label: 'C#' },
    { value: 'text', label: 'Text' },
  ];

  return (
    <Menu as="div" className="relative inline-block text-left">
      <div className="cursor-pointer hover:bg-gray-200 hover:text-gray-900 p-1 rounded flex items-center justify-center">
        <MenuButton className="inline-flex w-full justify-center gap-x-1.5 rounded-md px-1 text-sm font-semibold text-gray-500 h-5">
          {languages.find((lang) => lang.value === selectedLanguage)?.label || 'Select Language'}
          <ChevronDownIcon aria-hidden="true" className="-mr-1 h-5 w-5 text-gray-500" />
        </MenuButton>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <MenuItems className="absolute right-0 z-10 mt-2 w-40 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            {languages.map((language) => (
              <HeadlessMenuItem key={language.value}>
                {({ active }) => (
                  <button
                    onClick={() => onLanguageChange(language.value)}
                    className={`${active ? 'bg-gray-100 text-gray-900' : 'text-gray-700'
                      } block px-4 py-2 text-sm w-full text-left flex items-center justify-between`}
                  >
                    {language.label}
                    {language.value === selectedLanguage && (
                      <CheckIcon aria-hidden="true" className="h-5 w-5 text-green-600" /> // Render checkmark if selected
                    )}
                  </button>
                )}
              </HeadlessMenuItem>
            ))}
          </div>
        </MenuItems>
      </Transition>
    </Menu>
  );
};

export default LanguageSelector;
