import { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { auth, googleProvider } from "./config/firebase";
import { Link } from 'react-router-dom';
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
  User,
  sendPasswordResetEmail
} from "firebase/auth";


interface ResetPasswordProps {
  email: string;
}

export const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [user, setUser] = useState<User | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [resetEmail, setResetEmail] = useState("");


  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        setUser(authUser);
        authUser.getIdToken().then((token) => {
        });
      } else {
        setUser(null);
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {

    document.title = 'Forgot Password | HinterviewGPT';

  }, []);

  const signIn = async () => {
    try {
      setError(null);
      await signInWithEmailAndPassword(auth, email, password);
      navigate(`/questions`);
    } catch (err: any) {
      setError("Invalid Email or Password. The provided email address is not registered.");
      console.log("Error creating user:", err);
      console.log(err);
    }
  };

  const resetPassword = async ({ email }: ResetPasswordProps): Promise<void> => {
    try {
      // Send password reset email
      await sendPasswordResetEmail(auth, email);

      setSuccess("Password reset email sent successfully!");
      setError(null);
    } catch (error) {
      // Handle errors here
      console.error('Error sending password reset email:', error);
  
      setError("Error sending password reset email. Email not found.");
      setSuccess(null);
    }
  };

  return (
    <div style={{ height: "80vh" }}>
      <div className="auth-container" style={{ marginTop: "100px" }}>
        <div className="user-status">
          Reset Password
        </div>
        {error && <div style={{ color: "red" ,marginBottom: "10px" }}>{error}</div>}
        {success && <div style={{ color: "green",marginBottom: "10px"  }}>{success}</div>}
        <input
          placeholder="Email..."
          onChange={(e) => setEmail(e.target.value)}
        />
        <button onClick={() => resetPassword({ email })}>Reset Password</button>

      </div>
    </div>
  );
};
