import { useEffect, useState } from 'react';
import { auth } from './config/firebase';
import { User } from "firebase/auth";
import Navbar from './navbar';
import { useNavigate } from 'react-router-dom';

const Success = () => {
    const [user, setUser] = useState<User | null>(null); // User state
    const [userSet, setUserSet] = useState(false); // New state variable

    const navigate = useNavigate();

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((authUser) => {
            if (authUser) {
                setUser(authUser);
                setUserSet(true);
            } else {
                setUser(null);
                setUserSet(false);
                navigate('/auth');
            }
        });

        return () => unsubscribe();
    }, [navigate]);

    useEffect(() => {
        document.title = 'Subscription Success | HinterviewGPT';
    }, []);

    return (
        <div className="flex flex-col min-h-screen bg-gray-50">
            {/* Navbar */}
            <Navbar currentNav="" />

            {/* Success message */}
            <div className="flex-grow flex flex-col items-center justify-center py-12 bg-gray-50 sm:px-6 lg:px-8">
                <div className="max-w-md w-full space-y-8 text-center">
                    <h2 className="text-3xl font-extrabold text-gray-900">
                        🎉 Subscription Successful!
                    </h2>
                    <p className="mt-4 text-lg text-gray-600">
                        Thank you for subscribing! Your subscription is now active, and you can start enjoying all premium features of HinterviewGPT.
                    </p>

                    {/* <div className="mt-8">
                        <img 
                            src="https://images.unsplash.com/photo-1517245386807-bb43f82c33c4?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjUyOXwwfDF8c2VhcmNofDJ8fGNvbmdyYXR1bGF0aW9uc3xlbnwwfHx8fDE2MzYwNzI0MjU&ixlib=rb-1.2.1&q=80&w=400" 
                            alt="Success" 
                            className="rounded-lg shadow-lg mx-auto"
                        />
                    </div> */}

                    <div className="mt-8">
                        <button
                            onClick={() => navigate('/activity')}
                            className="w-full bg-indigo-600 text-white py-2 px-4 rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 transition duration-150 ease-in-out"
                        >
                            Go to Dashboard
                        </button>

                        <button
                            onClick={() => navigate('/account')}
                            className="w-full mt-4 bg-gray-200 text-gray-800 py-2 px-4 rounded-md hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 transition duration-150 ease-in-out"
                        >
                            View Account Details
                        </button>
                    </div>
                </div>
            </div>

            {/* Footer */}
            <div className="bg-white border-t border-gray-200 mt-auto">
                <div className="mx-auto max-w-7xl py-3 px-4 sm:px-6 lg:px-8 flex justify-between items-center text-sm">
                    <p className="text-gray-500">
                        © 2024 HinterviewGPT. All rights reserved.
                    </p>
                    <div>
                        <a href="/privacy" className="text-indigo-600 hover:text-indigo-900 ml-4">
                            Privacy Policy
                        </a>
                        <a href="/tos" className="text-indigo-600 hover:text-indigo-900 ml-4">
                            Terms of Service
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Success;
