import React from 'react';
import ReactMarkdown from 'react-markdown';
import remarkMath from 'remark-math';
import rehypeKatex from 'rehype-katex';
import 'katex/dist/katex.min.css';
import MainRouter from './MainRouter';


const markdown = `
To find the second derivative of the function $ f(x) = e^{x^2} \\sin(x) $ at $ x = 0 $, 

The formula is:
$$
f''(x) = \\frac{d^2}{dx^2} \\left( e^{x^2} \\sin(x) \\right)
$$
`;

function App() {
  return (
    // <div className="App">
    //   <header className="App-header">
    //     <h1>Markdown with Math</h1>
    //   </header>
    //   <main>
    //     <ReactMarkdown
    //       children={markdown}
    //       remarkPlugins={[remarkMath]}
    //       rehypePlugins={[rehypeKatex]}
    //     />
    //   </main>
    // </div>
    <MainRouter />

  );
}

export default App;
