import { useEffect, useState } from 'react';
import { auth, db } from './config/firebase';
import { User } from "firebase/auth";
import { collection, addDoc } from "firebase/firestore";
import Navbar from './navbar';
import { useNavigate } from 'react-router-dom';
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/react';
import { MinusSmallIcon, PlusSmallIcon } from '@heroicons/react/24/outline';

const faqs = [
    {
        question: "How do I get an OpenAI API key?",
        answer:
            "To get an OpenAI API key, you need to sign up for an account on the OpenAI platform. You can do so by visiting <a href='https://platform.openai.com/signup' target='_blank' rel='noopener noreferrer' class='text-indigo-600 hover:text-indigo-900'>OpenAI Sign Up Page</a>. Once you have an account, navigate to the <a href='https://platform.openai.com/account/api-keys' target='_blank' rel='noopener noreferrer' class='text-indigo-600 hover:text-indigo-900'>API Keys Section</a> of the OpenAI dashboard. There, you can create and manage your API keys. Make sure to keep your API key secure and do not share it publicly.",
    },
    {
        question: "How does HinterviewGPT use my OpenAI API key?",
        answer:
            "HinterviewGPT is designed with your privacy and security in mind. Your OpenAI API key is never stored on our backend servers. Instead, it is securely stored in your browser's local storage, ensuring that only you have access to it. The OpenAI API is called directly from your browser during specific operations, including generating interview questions, using the HinterviewGPT chat, evaluating your submitted solutions, and when you choose to regenerate a solution in the 'Solution' tab on the practice page. This approach ensures that your API key remains secure while allowing HinterviewGPT to provide its full range of features.",
    },
    {
        question: "How do I track the usage of my OpenAI API key?",
        answer:
            "You can track the usage of your OpenAI API key by logging into your OpenAI account and navigating to the <a href='https://platform.openai.com/account/usage' target='_blank' rel='noopener noreferrer' class='text-indigo-600 hover:text-indigo-900'>Usage Section</a> of the dashboard. This section provides detailed insights into your API usage, including the number of tokens consumed, the specific endpoints accessed, and the associated costs. This information is updated in real-time, allowing you to monitor and manage your usage effectively.",
    },
    {
        question: "Why does it say 'Not Activated' in the navbar?",
        answer:
            "This means that you have not yet activated your OpenAI API key. To activate your API key, you need to click the 'Not Activated' button then enter your OpenAI API key in the dialog box that appears.  Then click the 'Activate' button to confirm.",
    },
    {
        question: "How do I create a question to practice?",
        answer:
            "To create a question for practice, begin by clicking the 'Add Question' link in the navbar. You will be presented with two options: 'Manually Add Question' and 'HinterviewGPT Generation.' If you choose 'Manually Add Question,' you can enter the question you wish to practice manually. This includes providing a title, selecting the answer type, and adding relevant tags. Alternatively, if you select 'HinterviewGPT Generation,' you can describe a topic you'd like to practice, and HinterviewGPT will generate a realistic question tailored to your needs. This allows you to create a customized practice experience that aligns with your goals.",
    },
    {
        question: "How do I submit a solution to a question?",
        answer:
            "When you are practicing a question and have crafted a solution you are satisfied with on the whiteboard, simply click the 'Submit' button. HinterviewGPT will then evaluate your solution. You can view all of your submitted solutions for a question by navigating to the 'Submissions' tab, where each attempt is recorded for your review and improvement.",
    },
];

const footerNavigation = {
    solutions: [
        { name: 'Hosting', href: '#' },
        { name: 'Data Services', href: '#' },
        { name: 'Uptime Monitoring', href: '#' },
        { name: 'Enterprise Services', href: '#' },
    ],
    support: [
        { name: 'Pricing', href: '#' },
        { name: 'Documentation', href: '#' },
        { name: 'Guides', href: '#' },
        { name: 'API Reference', href: '#' },
    ],
    company: [
        { name: 'About', href: '#' },
        { name: 'Blog', href: '#' },
        { name: 'Jobs', href: '#' },
        { name: 'Press', href: '#' },
        { name: 'Partners', href: '#' },
    ],
    legal: [
        { name: 'Privacy', href: '/privacy' },  // Privacy Policy Link
        { name: 'Terms', href: '/tos' },        // Terms of Service Link
    ],
    social: [
        {
            name: 'Instagram',
            href: '#',
            icon: (props: any) => (
                <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
                    <path
                        fillRule="evenodd"
                        d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
                        clipRule="evenodd"
                    />
                </svg>
            ),
        },
        {
            name: 'X',
            href: '#',
            icon: (props: any) => (
                <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
                    <path d="M13.6823 10.6218L20.2391 3H18.6854L12.9921 9.61788L8.44486 3H3.2002L10.0765 13.0074L3.2002 21H4.75404L10.7663 14.0113L15.5685 21H20.8131L13.6819 10.6218H13.6823ZM11.5541 13.0956L10.8574 12.0991L5.31391 4.16971H7.70053L12.1742 10.5689L12.8709 11.5655L18.6861 19.8835H16.2995L11.5541 13.096V13.0956Z" />
                </svg>
            ),
        },

        {
            name: 'YouTube',
            href: '#',
            icon: (props: any) => (
                <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
                    <path
                        fillRule="evenodd"
                        d="M19.812 5.418c.861.23 1.538.907 1.768 1.768C21.998 8.746 22 12 22 12s0 3.255-.418 4.814a2.504 2.504 0 0 1-1.768 1.768c-1.56.419-7.814.419-7.814.419s-6.255 0-7.814-.419a2.505 2.505 0 0 1-1.768-1.768C2 15.255 2 12 2 12s0-3.255.417-4.814a2.507 2.507 0 0 1 1.768-1.768C5.744 5 11.998 5 11.998 5s6.255 0 7.814.418ZM15.194 12 10 15V9l5.194 3Z"
                        clipRule="evenodd"
                    />
                </svg>
            ),
        },
    ],
};

const Help = () => {
    const [user, setUser] = useState<User | null>(null);
    const [userSet, setUserSet] = useState(false);
    const [email, setEmail] = useState('');
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');
    const [status, setStatus] = useState<{ type: 'success' | 'error' | '', message: string }>({ type: '', message: '' });

    const navigate = useNavigate();

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((authUser) => {
            if (authUser) {
                setUser(authUser);
                setUserSet(true);
            } else {
                setUser(null);
                setUserSet(false);
                navigate('/auth');
            }
        });

        return () => unsubscribe();
    }, [navigate]);

    useEffect(() => {
        document.title = 'Help Center | HinterviewGPT';
    }, []);

    const isValidEmail = (email: string) => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(email);
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        if (!isValidEmail(email)) {
            setStatus({ type: 'error', message: 'Please enter a valid email address.' });
            return;
        }
        if (subject.trim() === '') {
            setStatus({ type: 'error', message: 'Please enter a subject.' });
            return;
        }
        if (message.trim() === '') {
            setStatus({ type: 'error', message: 'Please enter a message.' });
            return;
        }

        if (user) {
            try {
                const helpCollectionRef = collection(db, "help");
                await addDoc(helpCollectionRef, {
                    userId: user.uid,
                    email,
                    subject,
                    message,
                    resolved: false,
                    createdAt: new Date()
                });
                setStatus({ type: 'success', message: 'Message sent successfully!' });
                setEmail('');
                setSubject('');
                setMessage('');
            } catch (error) {
                console.error("Error adding document: ", error);
                setStatus({ type: 'error', message: 'There was an error sending your message. Please try again.' });
            }
        } else {
            setStatus({ type: 'error', message: 'User not authenticated' });
        }
    };

    return (
        <div className="flex flex-col min-h-screen bg-white ">
            {/* Navbar */}
            <Navbar currentNav="Help" />

            {/* Main content */}
            <div className="flex-grow bg-white mb-12">
                <div className="mx-auto max-w-7xl px-6 py-14 sm:py-22 lg:px-8 lg:py-22">
                    <div className="mx-auto max-w-4xl divide-y divide-gray-900/10">
                        <h2 className="text-2xl font-bold leading-10 tracking-tight text-gray-900">Help Center</h2>
                        <dl className="mt-10 space-y-6 divide-y divide-gray-900/10">
                            {faqs.map((faq) => (
                                <Disclosure key={faq.question} as="div" className="pt-6">
                                    <dt>
                                        <DisclosureButton className="group flex w-full items-start justify-between text-left text-gray-900">
                                            <span className="text-base font-semibold leading-7">{faq.question}</span>
                                            <span className="ml-6 flex h-7 items-center">
                                                <PlusSmallIcon aria-hidden="true" className="h-6 w-6 group-data-[open]:hidden" />
                                                <MinusSmallIcon aria-hidden="true" className="h-6 w-6 [.group:not([data-open])_&]:hidden" />
                                            </span>
                                        </DisclosureButton>
                                    </dt>
                                    <DisclosurePanel as="dd" className="mt-2 pr-12">
                                        <p className="text-base leading-7 text-gray-600" dangerouslySetInnerHTML={{ __html: faq.answer }} />
                                    </DisclosurePanel>
                                </Disclosure>
                            ))}
                        </dl>
                    </div>
                </div>

                <div className="mx-auto max-w-7xl px-6">
                    <div className="mx-auto max-w-4xl divide-y divide-gray-900/10">
                        <h2 className="text-xl font-bold leading-10 tracking-tight text-gray-900">Still can't find what you're looking for?</h2>
                    </div>
                </div>
                <div className="bg-white p-8 rounded-lg border w-full max-w-3xl mx-auto mt-8">
                    <h2 className="text-2xl font-bold mb-2 text-gray-800">Contact Us</h2>
                    <p className="text-gray-600 mb-6">If you are experiencing technical issues or have any other questions, please reach out to us. We will respond as soon as possible.</p>
                    <form onSubmit={handleSubmit}>
                        <div className="mb-4">
                            <label htmlFor="email" className="block text-gray-700 text-sm font-bold mb-2">Your email</label>
                            <input
                                type="email"
                                id="email"
                                name="email"
                                className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                            />
                        </div>
                        <div className="mb-4">
                            <label htmlFor="subject" className="block text-gray-700 text-sm font-bold mb-2">Subject</label>
                            <input
                                type="text"
                                id="subject"
                                name="subject"
                                className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                value={subject}
                                onChange={(e) => setSubject(e.target.value)}
                                required
                            />
                        </div>
                        <div className="mb-4">
                            <label htmlFor="message" className="block text-gray-700 text-sm font-bold mb-2">Your message</label>
                            <textarea
                                id="message"
                                name="message"
                                className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline h-32"
                                value={message}
                                onChange={(e) => setMessage(e.target.value)}
                                required
                            ></textarea>
                        </div>
                        <div className="flex items-center justify-between">
                            <button type="submit" className="rounded-md bg-gray-200 px-3.5 py-2.5 text-sm font-semibold text-black shadow-sm hover:bg-gray-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                                Submit
                            </button>
                        </div>
                    </form>
                    {status.type && (
                        <div className={`mt-4 p-4 rounded ${status.type === 'success' ? 'bg-green-100 text-green-700' : 'bg-red-100 text-red-700'}`}>
                            {status.type === 'success' ? (
                                <svg className="w-6 h-6 inline-block mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                                </svg>
                            ) : (
                                <svg className="w-6 h-6 inline-block mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
                                </svg>
                            )}
                            {status.message}
                        </div>
                    )}
                </div>
            </div>

            <div className="bg-white border-t border-gray-200 mt-auto">
                <div className="mx-auto max-w-7xl py-3 px-4 sm:px-6 lg:px-8 flex justify-between items-center text-sm">
                    <p className="text-gray-500">
                        © 2024 HinterviewGPT. All rights reserved.
                    </p>
                    <div>
                        <a href="/privacy" className="text-indigo-600 hover:text-indigo-900 ml-4">
                            Privacy Policy
                        </a>
                        <a href="/tos" className="text-indigo-600 hover:text-indigo-900 ml-4">
                            Terms of Service
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Help;
