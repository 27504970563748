import { db } from "../config/firebase";
import { doc, getDoc, collection, query, where, getDocs } from "firebase/firestore";
import { subMonths, format } from 'date-fns';


interface DayActivity {
    addCount: number;
    submitCount: number;
}

interface MonthlyActivity {
    [date: string]: DayActivity;
}

const getLastYearActivity = async (userId: string): Promise<MonthlyActivity> => {

    try {
        const today = new Date();
        const pastYearMonths = Array.from({ length: 12 }, (_, i) => subMonths(today, i)).map(date => format(date, 'yyyy-MM'));
        const monthlyActivities: MonthlyActivity = {};

        const fetchMonthData = async (month: string) => {
            const docRef = doc(db, "activity", userId, "monthlyActivity", month);
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                const data = docSnap.data() as MonthlyActivity;
                Object.assign(monthlyActivities, data);
            }
        };

        await Promise.all(pastYearMonths.map(month => fetchMonthData(month)));

        return monthlyActivities;
    } catch (error) {
        console.error("Error fetching activity data:", error);
        return {};
    }
};

export default getLastYearActivity;