import { useEffect, useState } from 'react';
import { auth, db } from './config/firebase';
import { User } from "firebase/auth";
import { collection, addDoc } from "firebase/firestore"; 
import Navbar from './navbar';
import { useNavigate } from 'react-router-dom';

const Error = () => {
    const [user, setUser] = useState<User | null>(null); // User state
    const [userSet, setUserSet] = useState(false); // New state variable

    const navigate = useNavigate();

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((authUser) => {
            if (authUser) {
                setUser(authUser);
                setUserSet(true);
            } else {
                setUser(null);
                setUserSet(false);
                navigate('/auth');
            }
        });

        return () => unsubscribe();
    }, [navigate]);

    useEffect(() => {

      document.title = 'Error | HinterviewGPT';
  
    }, []);

   
    return (
        <main className="mx-auto flex w-full max-w-7xl flex-auto flex-col justify-center px-6 py-24 sm:py-64 lg:px-8">
        <p className="text-base font-semibold leading-8 text-indigo-600">404</p>
        <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">Page not found</h1>
        <p className="mt-6 text-base leading-7 text-gray-600">Sorry, we couldn’t find the page you’re looking for.</p>
        <div className="mt-10">
          <a href="/" className="text-sm font-semibold leading-7 text-indigo-600">
            <span aria-hidden="true">&larr;</span> Back to home
          </a>
        </div>
      </main>
    );
};

export default Error;
