import { db } from "../config/firebase";
import { doc, setDoc, getDoc } from "firebase/firestore";
import { format } from 'date-fns';

const saveActivity = async (userId: string, activityType: string) => {
  const today = new Date();
  const todayString = format(today, "yyyy-MM-dd"); // Format: YYYY-MM-DD
  const currentMonth = format(today, "yyyy-MM"); // Format: YYYY-MM for the monthly document
  const docRef = doc(db, "activity", userId, "monthlyActivity", currentMonth);

  try {
    // Check if the monthly document already exists
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      // Monthly document exists, update the activity count for the specified day and activity type
      const data = docSnap.data();
      const currentDayData = data[todayString] || { addCount: 0, submitCount: 0 };
      currentDayData[activityType] = (currentDayData[activityType] || 0) + 1;

      await setDoc(docRef, { [todayString]: currentDayData }, { merge: true });
    } else {
      // Monthly document does not exist, create it with the current day's activity data
      await setDoc(docRef, {
        [todayString]: { [activityType]: 1, addCount: activityType === 'addCount' ? 1 : 0, submitCount: activityType === 'submitCount' ? 1 : 0 }
      });
    }
  } catch (error) {
    console.error("Error saving activity for user:", userId, "on date:", todayString, "Activity Type:", activityType, "Error:", error);
  }
};

export default saveActivity;
