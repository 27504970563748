import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth } from './config/firebase';
import { User } from "firebase/auth";
import Navbar from './navbar';
import { PencilSquareIcon, SparklesIcon } from '@heroicons/react/24/outline';
import { IconArrowRight, IconEdit, IconPencil, IconSparkles } from '@tabler/icons-react';

const AddQuestion = () => {
    const [user, setUser] = useState<User | null>(null); // User state

    const navigate = useNavigate();

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((authUser) => {
            if (authUser) {
                // User is signed in.
                setUser(authUser);
            } else {
                // No user is signed in.
                setUser(null);
                navigate('/auth');
            }
        });

        return () => unsubscribe(); // Unsubscribe on unmount
    }, []);

    useEffect(() => {
        document.title = 'Add Question | HinterviewGPT';
    }, []);

    const footerNavigation = {
        solutions: [
            { name: 'Hosting', href: '#' },
            { name: 'Data Services', href: '#' },
            { name: 'Uptime Monitoring', href: '#' },
            { name: 'Enterprise Services', href: '#' },
        ],
        support: [
            { name: 'Pricing', href: '#' },
            { name: 'Documentation', href: '#' },
            { name: 'Guides', href: '#' },
            { name: 'API Reference', href: '#' },
        ],
        company: [
            { name: 'About', href: '#' },
            { name: 'Blog', href: '#' },
            { name: 'Jobs', href: '#' },
            { name: 'Press', href: '#' },
            { name: 'Partners', href: '#' },
        ],
        legal: [
            { name: 'Privacy', href: '/privacy' },  // Privacy Policy Link
            { name: 'Terms', href: '/tos' },        // Terms of Service Link
        ],
        social: [
            {
                name: 'Instagram',
                href: '#',
                icon: (props: any) => (
                    <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
                        <path
                            fillRule="evenodd"
                            d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
                            clipRule="evenodd"
                        />
                    </svg>
                ),
            },
            {
                name: 'X',
                href: '#',
                icon: (props: any) => (
                    <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
                        <path d="M13.6823 10.6218L20.2391 3H18.6854L12.9921 9.61788L8.44486 3H3.2002L10.0765 13.0074L3.2002 21H4.75404L10.7663 14.0113L15.5685 21H20.8131L13.6819 10.6218H13.6823ZM11.5541 13.0956L10.8574 12.0991L5.31391 4.16971H7.70053L12.1742 10.5689L12.8709 11.5655L18.6861 19.8835H16.2995L11.5541 13.096V13.0956Z" />
                    </svg>
                ),
            },

            {
                name: 'YouTube',
                href: '#',
                icon: (props: any) => (
                    <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
                        <path
                            fillRule="evenodd"
                            d="M19.812 5.418c.861.23 1.538.907 1.768 1.768C21.998 8.746 22 12 22 12s0 3.255-.418 4.814a2.504 2.504 0 0 1-1.768 1.768c-1.56.419-7.814.419-7.814.419s-6.255 0-7.814-.419a2.505 2.505 0 0 1-1.768-1.768C2 15.255 2 12 2 12s0-3.255.417-4.814a2.507 2.507 0 0 1 1.768-1.768C5.744 5 11.998 5 11.998 5s6.255 0 7.814.418ZM15.194 12 10 15V9l5.194 3Z"
                            clipRule="evenodd"
                        />
                    </svg>
                ),
            },
        ],
    };

    return (
        <div className="flex flex-col min-h-screen">
            <Navbar currentNav="Add Question" />
            <div className="flex-grow bg-white">
                <div className="mx-auto max-w-7xl py-4 px-4 sm:px-6 lg:px-4">
                    <div className="px-28">
                        <div className="p-4 md:p-5 mx-auto ">
                            <h2 className="text-2xl font-bold mb-6 text-gray-800">Add Question</h2>
                            <p className="text-gray-500 dark:text-gray-400 mb-4">You can add an interview question in two ways:</p>
                            <ul className="space-y-4 mb-4">
                                <li>
                                    <input type="radio" id="job-1" name="job" value="job-1" className="hidden peer" required />
                                    <label
                                        htmlFor="job-1"
                                        onClick={() => navigate(`/add-manual`)}
                                        className="inline-flex items-center justify-between w-full p-5 text-gray-900 bg-white border border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-500 dark:peer-checked:text-blue-500 peer-checked:border-blue-600 peer-checked:text-blue-600 hover:text-gray-900 hover:bg-gray-100 dark:text-white dark:bg-gray-600 dark:hover:bg-gray-500"
                                    >
                                        <div className="flex items-center">
                                            <span
                                                className='inline-flex h-24 w-24 items-center justify-center rounded-lg'>
                                                <IconEdit className="w-24 h-24 mr-3 text-gray-600 dark:text-gray-400" />
                                            </span>
                                            <div className="block">
                                                <div className="w-full text-lg font-semibold">Manually Add Question</div>
                                                <div className="w-full text-gray-500 dark:text-gray-400">Manually enter the question that you would like to practice. Use this option if you already know the question you would like to practice.</div>
                                            </div>
                                        </div>
                                        <IconArrowRight className="w-16 h-16 ms-3 rtl:rotate-180 text-gray-600 dark:text-gray-400 ml-12" />
                                    </label>
                                </li>
                                <li>
                                    <input type="radio" id="job-2" name="job" value="job-2" className="hidden peer" />
                                    <label
                                        htmlFor="job-2"
                                        onClick={() => navigate(`/generate-question`)}
                                        className="inline-flex items-center justify-between w-full p-5 text-gray-900 bg-white border border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-500 dark:peer-checked:text-blue-500 peer-checked:border-blue-600 peer-checked:text-blue-600 hover:text-gray-900 hover:bg-gray-100 dark:text-white dark:bg-gray-600 dark:hover:bg-gray-500"
                                    >
                                        <div className="flex items-center">
                                            <span
                                                className='inline-flex h-24 w-24 items-center justify-center rounded-lg'>
                                                <IconSparkles className="w-24 h-24 mr-3 text-gray-600 dark:text-gray-400" />
                                            </span>
                                            <div className="block">
                                                <div className="w-full text-lg font-semibold">HinterviewGPT Generation</div>
                                                <div className="w-full text-gray-500 dark:text-gray-400">Enter a short description of the type of question you are interested in and HinterviewGPT will generate a realistic interview question for you to practice.</div>
                                            </div>
                                        </div>
                                        <IconArrowRight className="w-16 h-16 ms-3 rtl:rotate-180 text-gray-600 dark:text-gray-400 ml-12" />
                                    </label>
                                </li>
                            </ul>

                        </div>
                    </div>
                </div>
            </div>
            <div className="bg-white border-t border-gray-200 mt-auto">
                <div className="mx-auto max-w-7xl py-3 px-4 sm:px-6 lg:px-8 flex justify-between items-center text-sm">
                    <p className="text-gray-500">
                        © 2024 HinterviewGPT. All rights reserved.
                    </p>
                    <div>
                        <a href="/privacy" className="text-indigo-600 hover:text-indigo-900 ml-4">
                            Privacy Policy
                        </a>
                        <a href="/tos" className="text-indigo-600 hover:text-indigo-900 ml-4">
                            Terms of Service
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddQuestion;
