import React from 'react';
import { useNavigate } from 'react-router-dom';

const NoAccess = () => {
    const navigate = useNavigate();

    return (
        <div className="flex flex-col min-h-screen justify-center items-center bg-gray-100">
            <h1 className="text-4xl font-bold text-gray-800 mb-6">No Access</h1>
            <p className="text-lg text-gray-600 mb-6">
                You currently do not have access. Your trial may have expired or you may need to subscribe to a plan.
            </p>
            <button
                onClick={() => navigate('/')} // Navigate to the pricing page or another relevant page
                className="bg-indigo-600 text-white px-4 py-2 rounded-md hover:bg-indigo-700"
            >
                HinterviewGPT Home
            </button>
        </div>
    );
};

export default NoAccess;
