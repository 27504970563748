import { Disclosure } from '@headlessui/react';
import { PlusSmallIcon, MinusSmallIcon, ChevronUpIcon, ChevronDownIcon } from '@heroicons/react/24/outline';
import { IconFileCode } from '@tabler/icons-react';

const QuestionDetailsAccordion = ({
  createDate,
  createMethod,
  createModel,
  isExpanded,
  setIsExpanded,
}: {
  createDate: string;
  createMethod: string;
  createModel: string;
  isExpanded: boolean;
  setIsExpanded: (expanded: boolean) => void;
}) => {
  return (
    <div className="border-t border-b border-gray-900/10 mt-8">
      <dl className="space-y-6">
        <div className="pt-3 pb-3">
          <dt>
            <button
              className="group flex w-full items-center justify-between text-left text-gray-900"
              onClick={() => setIsExpanded(!isExpanded)}
            >
              <div className="flex items-center">
                <IconFileCode className="h-5 w-5 text-gray-500 mr-1" aria-hidden="true" />
                <span className="text-base font-medium leading-7">
                  Question Details
                </span>
              </div>
              <span className="ml-6 flex h-7 items-center mr-1">
                <ChevronDownIcon
                  aria-hidden="true"
                  className={`h-6 w-6 text-gray-400 hover:text-gray-800 ${isExpanded ? 'hidden' : 'block'}`}
                />
                <ChevronUpIcon
                  aria-hidden="true"
                  className={`h-6 w-6 text-gray-400 hover:text-gray-800 ${!isExpanded ? 'hidden' : 'block'}`}
                />
              </span>
            </button>
          </dt>
          {isExpanded && (
            <dd className="mt-2 pr-12 pl-6">
              <p className="text-sm leading-6 text-gray-600">
                <strong>Creation Date:</strong> {createDate?.toLocaleString()}
              </p>
              <p className="text-sm leading-6 text-gray-600 mt-1">
                <strong>Creation Method:</strong>
                <span className="inline-flex items-center rounded-md bg-gray-400/10 px-2 py-1 ml-1 text-xs font-medium mr-2 text-gray-500 ring-1 ring-inset ring-gray-400/20">
                  {createMethod?.charAt(0).toUpperCase() + createMethod?.slice(1)}
                </span>

              </p>
              {createMethod !== 'manual' && (
                <p className="text-sm leading-6 text-gray-600 mt-1">
                  <strong>Model Used:</strong>
                  <span className="inline-flex items-center rounded-md bg-gray-400/10 px-2 py-1 ml-1 text-xs font-medium mr-2 text-gray-500 ring-1 ring-inset ring-gray-400/20">
                    {createModel}
                  </span>

                </p>
              )}
            </dd>

          )}
        </div>
      </dl>
    </div>
  );
};
export default QuestionDetailsAccordion;