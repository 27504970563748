import { useEffect, useState } from 'react';
import TablePagination from '@mui/material/TablePagination';
import { db } from './config/firebase';
import { doc, getDoc, runTransaction, deleteDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { auth } from './config/firebase';
import { User } from "firebase/auth";
import Navbar from './navbar';
import { PlusIcon } from '@heroicons/react/24/solid';
import { Dialog, DialogPanel, DialogTitle, Transition, TransitionChild } from '@headlessui/react';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid';


type QuestionType = {
  qRef: string;
  title: string;
  tags: string[];
  difficulty: string;
  answerType: string;
  originalIndex: number; // Add this field
};


const QuestionList = () => {
  //const classes = useStyles();
  const [leetCodeQuestions, setLeetCodeQuestions] = useState<QuestionType[]>([]);
  const [originalData, setOriginalData] = useState<QuestionType[]>([]);

  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('desc');
  const [sortColumn, setSortColumn] = useState<string>('qNum');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [user, setUser] = useState<User | null>(null); // User state
  const [userSet, setUserSet] = useState(false); // New state variable
  const [searchQuery, setSearchQuery] = useState<string>(''); // State for search query
  const [questionSubmissions, setQuestionSubmissions] = useState<Record<string, number>>({});
  const [loading, setLoading] = useState(true);
  const [qloading, setqLoading] = useState(true);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [questionToDelete, setQuestionToDelete] = useState<string | null>(null);
  const [open, setOpen] = useState(false);
  const [selectedQuestion, setSelectedQuestion] = useState<string | null>(null);
  const count = leetCodeQuestions.length; // total questions

  const handlePrevious = () => {
    if (page > 0) {
      setPage(page - 1);
    }
  };

  const handleNext = () => {
    const totalPages = Math.ceil(count / rowsPerPage);
    if (page < totalPages - 1) {
      setPage(page + 1);
    }
  };

  const handlePageClick = (index: number) => {
    setPage(index);
  };

  const totalPages = Math.ceil(count / rowsPerPage);


  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        setUser(authUser);
        setUserSet(true);
      } else {
        setUser(null);
        setUserSet(false);
        navigate('/auth');
      }
      setLoading(false);

    });

    return () => unsubscribe();
  }, [sortOrder, sortColumn, page, rowsPerPage, searchQuery]);

  useEffect(() => {

    document.title = 'Question List | HinterviewGPT';

  }, []);

  const footerNavigation = {
    solutions: [
      { name: 'Hosting', href: '#' },
      { name: 'Data Services', href: '#' },
      { name: 'Uptime Monitoring', href: '#' },
      { name: 'Enterprise Services', href: '#' },
    ],
    support: [
      { name: 'Pricing', href: '#' },
      { name: 'Documentation', href: '#' },
      { name: 'Guides', href: '#' },
      { name: 'API Reference', href: '#' },
    ],
    company: [
      { name: 'About', href: '#' },
      { name: 'Blog', href: '#' },
      { name: 'Jobs', href: '#' },
      { name: 'Press', href: '#' },
      { name: 'Partners', href: '#' },
    ],
    legal: [
      { name: 'Privacy', href: '/privacy' },  // Privacy Policy Link
      { name: 'Terms', href: '/tos' },        // Terms of Service Link
    ],
    social: [

      {
        name: 'Instagram',
        href: '#',
        icon: (props: any) => (
          <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
            <path
              fillRule="evenodd"
              d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
              clipRule="evenodd"
            />
          </svg>
        ),
      },
      {
        name: 'X',
        href: '#',
        icon: (props: any) => (
          <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
            <path d="M13.6823 10.6218L20.2391 3H18.6854L12.9921 9.61788L8.44486 3H3.2002L10.0765 13.0074L3.2002 21H4.75404L10.7663 14.0113L15.5685 21H20.8131L13.6819 10.6218H13.6823ZM11.5541 13.0956L10.8574 12.0991L5.31391 4.16971H7.70053L12.1742 10.5689L12.8709 11.5655L18.6861 19.8835H16.2995L11.5541 13.096V13.0956Z" />
          </svg>
        ),
      },

      {
        name: 'YouTube',
        href: '#',
        icon: (props: any) => (
          <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
            <path
              fillRule="evenodd"
              d="M19.812 5.418c.861.23 1.538.907 1.768 1.768C21.998 8.746 22 12 22 12s0 3.255-.418 4.814a2.504 2.504 0 0 1-1.768 1.768c-1.56.419-7.814.419-7.814.419s-6.255 0-7.814-.419a2.505 2.505 0 0 1-1.768-1.768C2 15.255 2 12 2 12s0-3.255.417-4.814a2.507 2.507 0 0 1 1.768-1.768C5.744 5 11.998 5 11.998 5s6.255 0 7.814.418ZM15.194 12 10 15V9l5.194 3Z"
              clipRule="evenodd"
            />
          </svg>
        ),
      },
    ],
  };

  const getLeetCodeQuestions = async () => {


    if (!user || loading) {
      return;
    }

    if (user) {
      try {
        const userQuestionsListRef = doc(db, 'userQuestionList', user.uid);
        const userQuestionsListDoc = await getDoc(userQuestionsListRef);

        if (userQuestionsListDoc.exists()) {
          const userQuestionsList = userQuestionsListDoc.data().questions || [];

          const queriedData: QuestionType[] = userQuestionsList.map((question: QuestionType, index: number) => {
            const { title, difficulty, qRef, tags, answerType } = question;

            return {
              qRef,
              title,
              tags,
              difficulty,
              answerType,
              originalIndex: index, // Set the original index
            };
          });

          const userSubmissionNumDocRef = doc(db, 'userSubmissionNum', user.uid);
          const userSubmissionNumDoc = await getDoc(userSubmissionNumDocRef);

          if (userSubmissionNumDoc.exists()) {
            const userData = userSubmissionNumDoc.data();
            const userQuestionSubmissions: Record<string, number> =
              userData?.questionSubmissions || {};

            setQuestionSubmissions(userQuestionSubmissions);
          }

          setOriginalData(queriedData);

          const filteredData = searchQuery
            ? queriedData.filter((question) => question.title.toLowerCase().includes(searchQuery.toLowerCase()))
            : queriedData;

          setLeetCodeQuestions(filteredData);
        }

      } catch (err) {
        console.error('Error getting documents: ', err);
      }
      setqLoading(false);
    }
  };


  useEffect(() => {
    // Handle local changes without fetching data from Firestore
    const queriedData = [...originalData];


    const filteredData = searchQuery
      ? queriedData.filter((question) =>
        question.title.toLowerCase().includes(searchQuery.toLowerCase())
      )
      : queriedData;

    setLeetCodeQuestions(filteredData);
  }, [sortOrder, sortColumn, searchQuery, originalData]);

  useEffect(() => {
    if (user) {
      // Only call getLeetCodeQuestions if the user has been set
      getLeetCodeQuestions();
    }
  }, [user]);

  const handleDeleteQuestion = async (questionRef: string) => {
    try {
      if (!user) {
        // Handle the case where user is null
        console.error('User is null. Cannot delete question.');
        return;
      }

      // Delete the question from userQuestionList collection
      const userQuestionsListRef = doc(db, 'userQuestionList', user.uid);
      await runTransaction(db, async (transaction) => {
        const userQuestionsListDoc = await transaction.get(userQuestionsListRef);

        // Check if userQuestionsListDoc.data() is not undefined
        if (userQuestionsListDoc.exists() && userQuestionsListDoc.data()) {
          const updatedQuestions = userQuestionsListDoc.data().questions.filter(
            (question: QuestionType) => question.qRef !== questionRef
          );
          transaction.update(userQuestionsListRef, { questions: updatedQuestions });
        }
      });

      // Delete the question from userQuestions collection
      const userQuestionRef = doc(db, 'userQuestions', questionRef);
      await deleteDoc(userQuestionRef);
      getLeetCodeQuestions();

      // Update local state or perform any additional logic as needed
    } catch (error) {
      console.error('Error deleting question:', error);
      // Handle errors or display a notification to the user
    }
    setDeleteModalOpen(false)
  };

  const handleDeleteClick = (question: string) => {
    setSelectedQuestion(question);
    setOpen(true);
  };

  const confirmDelete = () => {
    if (selectedQuestion) {
      handleDeleteQuestion(selectedQuestion);
    }
    setOpen(false);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
    setPage(0); // Reset page when the search query changes
  };

  const tableContainerStyle = {
    width: '90%', // Set the width to 80% of the container
    margin: '0 auto', // Center the table within its container (optional)
  };


  const handleAddQuestion = async () => {
    navigate(`/add`);
  }


  return (
    <>
      <div className="flex flex-col min-h-screen bg-white ">

        <Navbar currentNav="Question List" />
        <header className="bg-white">
          <div className="bg-white">
            <div className="mx-auto max-w-7xl px-4 py-4 sm:px-6 lg:px-8 flex items-center">
              <span className="text-2xl font-bold leading-6 text-gray-800 mt-4">Question List ({leetCodeQuestions.length})</span>
            </div>
          </div>
        </header>

        <div className="bg-white ">
          <div className="px-4 sm:px-6 lg:px-8 max-w-7xl mx-auto bg-white" >

            <div className="sm:flex sm:items-center">
              <div className="sm:flex-auto">
                <p className="mt-2 text-sm text-gray-700 mt-4">
                  A list of all the questions that you have added. Click the question name to practice.
                </p>
              </div>
              <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex sm:flex-row sm:items-center sm:space-x-4">
                <input
                  type="text"
                  placeholder="Search..."
                  value={searchQuery}
                  onChange={handleSearchChange}
                  className="rounded-md border border-gray-300 px-3 py-2 text-sm text-gray-900 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                />
                <button
                  type="button"
                  className="flex items-center rounded-md bg-gray-200 px-3 py-2 text-center text-sm font-semibold text-gray-900 shadow-sm hover:bg-gray-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  onClick={handleAddQuestion}
                >
                  <PlusIcon className="h-5 w-5 mr-1" />
                  Add Question
                </button>
              </div>

            </div>
            <div className="mt-8 flow-root">
              <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8 mb-14">
                <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                  <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg" style={{ borderColor: '#d4d9db' }}>
                    <table className="min-w-full divide-y divide-gray-300">
                      <thead style={{ backgroundColor: '#f5f8fa' }}>
                        <tr>
                          <th scope="col" className="px-2 py-2 text-left text-sm font-semibold text-gray-900">
                            #
                          </th>
                          <th scope="col" className="px-2 py-2 text-left text-sm font-semibold text-gray-900">
                            Question Name
                          </th>
                          <th scope="col" className="px-2 py-2 text-left text-sm font-semibold text-gray-900">
                            Tags
                          </th>
                          <th scope="col" className="px-2 py-2 text-left text-sm font-semibold text-gray-900">
                            Type
                          </th>
                          <th scope="col" className="px-2 py-2 text-center text-sm font-semibold text-gray-900">
                            Submissions
                          </th>
                          <th scope="col" className="relative py-2 pl-2 pr-4 sm:pr-6">
                            <span className="sr-only">Edit</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200 bg-white">
                        {leetCodeQuestions
                          .slice()
                          .reverse()
                          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                          .map((question, index) => (
                            <tr key={question.qRef} className={index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                              <td className="whitespace-nowrap px-2 py-2 text-xs text-gray-500 font-bold">
                                {question.originalIndex + 1}
                              </td>
                              <td className="py-2 pl-2 pr-3 text-sm font-medium text-gray-900 max-w-xs truncate">
                                <a
                                  href=""
                                  onClick={() => navigate(`/question/${question.qRef}`)}
                                  className="text-indigo-600 hover:text-indigo-900"
                                >
                                  {question.title}
                                </a>
                              </td>
                              <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
                                {Array.isArray(question.tags) ? (
                                  question.tags.map((tag, tagIndex) => (
                                    <span
                                      key={tagIndex}
                                      className="inline-flex items-center rounded-md bg-gray-400/10 px-2 py-1 text-xs font-medium mr-2 text-gray-500 ring-1 ring-inset ring-gray-400/20"
                                    >
                                      {tag}
                                    </span>
                                  ))
                                ) : (
                                  <span>No tags available</span>
                                )}
                              </td>
                              <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
                                <span className="inline-flex items-center rounded-md bg-gray-400/10 px-2 py-1 text-xs font-medium mr-1 text-gray-500 ring-1 ring-inset ring-gray-400/20">
                                  {question.answerType}
                                </span>
                              </td>
                              <td className="whitespace-nowrap px-2 py-2 text-xs text-gray-500 text-center font-bold">
                                {questionSubmissions[question.qRef] || 0}
                              </td>
                              <td className="relative whitespace-nowrap py-2 pl-2 pr-4 text-right text-sm font-medium sm:pr-6">
                                <button
                                  onClick={() => handleDeleteClick(question.qRef)}
                                  className="text-indigo-600 hover:text-indigo-900"
                                >
                                  Delete
                                </button>
                              </td>
                            </tr>
                          ))}
                      </tbody>

                    </table>

                    {leetCodeQuestions.length > 0 && (
  <div className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6">
    <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
      <div>
        <p className="text-sm text-gray-700">
          Showing{' '}
          <span className="font-medium">{page * rowsPerPage + 1}</span> to{' '}
          <span className="font-medium">
            {Math.min((page + 1) * rowsPerPage, count)}
          </span>{' '}
          of <span className="font-medium">{count}</span> results
        </p>
      </div>
      <div>
        <nav aria-label="Pagination" className="isolate inline-flex -space-x-px rounded-md shadow-sm">
          <button
            onClick={handlePrevious}
            className="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
            disabled={page === 0}
          >
            <span className="sr-only">Previous</span>
            <ChevronLeftIcon aria-hidden="true" className="h-5 w-5" />
          </button>
          {[...Array(totalPages)].map((_, index) => (
            <button
              key={index}
              onClick={() => handlePageClick(index)}
              className={`relative inline-flex items-center px-4 py-2 text-sm font-semibold ${index === page
                  ? 'bg-indigo-600 text-white z-10'
                  : 'text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50'
                }`}
            >
              {index + 1}
            </button>
          ))}
          <button
            onClick={handleNext}
            className="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
            disabled={page >= totalPages - 1}
          >
            <span className="sr-only">Next</span>
            <ChevronRightIcon aria-hidden="true" className="h-5 w-5" />
          </button>
        </nav>
      </div>
    </div>
  </div>
)}

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Transition show={open}>
          <Dialog className="relative z-10" onClose={() => setOpen(false)}>
            <TransitionChild
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </TransitionChild>

            <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
              <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                <TransitionChild
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <DialogPanel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                    <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                      <div className="sm:flex sm:items-start">
                        <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                          <ExclamationTriangleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                        </div>
                        <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                          <DialogTitle as="h3" className="text-base font-semibold leading-6 text-gray-900">
                            Delete question
                          </DialogTitle>
                          <div className="mt-2">
                            <p className="text-sm text-gray-500">
                              Are you sure you want to delete this question? This action cannot be undone.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                      <button
                        type="button"
                        className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                        onClick={confirmDelete}
                      >
                        Delete
                      </button>
                      <button
                        type="button"
                        className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                        onClick={() => setOpen(false)}
                        data-autofocus
                      >
                        Cancel
                      </button>
                    </div>
                  </DialogPanel>
                </TransitionChild>
              </div>
            </div>
          </Dialog>
        </Transition>

        <div className="bg-white border-t border-gray-200 mt-auto">
          <div className="mx-auto max-w-7xl py-3 px-4 sm:px-6 lg:px-8 flex justify-between items-center text-sm">
            <p className="text-gray-500">
              © 2024 HinterviewGPT. All rights reserved.
            </p>
            <div>
              <a href="/privacy" className="text-indigo-600 hover:text-indigo-900 ml-4">
                Privacy Policy
              </a>
              <a href="/tos" className="text-indigo-600 hover:text-indigo-900 ml-4">
                Terms of Service
              </a>
            </div>
          </div>
        </div>
      </div>

    </>

  );
};

export default QuestionList;
