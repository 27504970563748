import { useEffect, useState } from 'react';
import { auth } from './config/firebase';
import { User } from "firebase/auth";
import classes from './HeroText.module.css';

import { IconCheck } from '@tabler/icons-react';
import { db } from './config/firebase';
import { doc, getDoc, updateDoc, setDoc, getDocs, collection, query, where, addDoc } from 'firebase/firestore';
import { getFirestore, DocumentReference, DocumentData, onSnapshot } from 'firebase/firestore';
import { features } from 'process';
import { PaperClipIcon } from '@heroicons/react/20/solid'
import { Fragment } from 'react'
import { Disclosure, Menu, Transition } from '@headlessui/react'
import { Bars3Icon, BellIcon, XMarkIcon } from '@heroicons/react/24/outline'
import logo from './assets/_7afa50e7-4fe5-42b5-a782-8293c4c5172e-removebg-preview.png'
import Navbar from './navbar';
import { useNavigate, useParams } from 'react-router-dom';
import { httpsCallable } from 'firebase/functions';
import { functions } from './config/firebase';


interface PortalLinkResponse {
    url: string;
}

const Account = () => {
    const [user, setUser] = useState<User | null>(null); // User state
    const [userSet, setUserSet] = useState(false); // New state variable
    const [plan, setPlan] = useState('');
    const [apiKey, setApiKey] = useState<string>(() => localStorage.getItem('apiKey') || '');
    const [isEditable, setIsEditable] = useState<boolean>(false);
    const [isObscured, setIsObscured] = useState<boolean>(true);
    const [subscriptions, setSubscriptions] = useState<any[]>([]); // Subscriptions state
    const [cancelAtNextPeriod, setCancelAtNextPeriod] = useState<boolean>(false);
    const [subscriptionStatus, setSubscriptionStatus] = useState<string>('Inactive'); // Subscription status state
    const [trialRemaining, setTrialRemaining] = useState<number | null>(null);
    const [hasAccess, setHasAccess] = useState<boolean>(false);


    useEffect(() => {
        const fetchSubscriptions = async () => {
            const currentUser = auth.currentUser;
            if (!currentUser) return;

            const subscriptionsRef = collection(db, 'customers', currentUser.uid, 'subscriptions');
            const activeSubscriptionsQuery = query(subscriptionsRef, where('status', 'in', ['trialing', 'active']));

            const subscriptionSnap = await getDocs(activeSubscriptionsQuery);
            const subs: any[] = [];

            subscriptionSnap.forEach((doc) => {
                subs.push(doc.data());
            });

            if (subs.length > 0) {
                const activeSubscription = subs[0];
                if (activeSubscription.cancel_at_period_end) {
                    setCancelAtNextPeriod(true);
                }
                setSubscriptionStatus(activeSubscription.status);
                const planNickname = activeSubscription.items[0]?.price?.product?.name || '7-Day Free Trial'; // Fetch plan nickname
                setPlan(planNickname);
                return;
            } else {
                setPlan('7-Day Free Trial');
            }

            // 2. Check for free trial eligibility (7 days after sign-up)
            const signUpDate = new Date(currentUser.metadata.creationTime!); // Firebase stores sign-up date
            const currentDate = new Date();
            const timeDiff = currentDate.getTime() - signUpDate.getTime();
            const daysSinceSignUp = Math.floor(timeDiff / (1000 * 3600 * 24));

            // If within 7-day trial period
            if (daysSinceSignUp < 7) {
                setTrialRemaining(7 - daysSinceSignUp);
                console.log("User has a free trial");
                setHasAccess(true);
                return;
            }

            setHasAccess(false);
            setSubscriptionStatus('Inactive');
        };

        fetchSubscriptions();
    }, [user]);

    const navigate = useNavigate();


    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((authUser) => {
            if (authUser) {
                setUser(authUser);
                setUserSet(true);

            } else {
                setUser(null);
                setUserSet(false);
                navigate('/auth');
            }
        });

        return () => unsubscribe();
    }, []);

    useEffect(() => {

        document.title = 'Account Information | HinterviewGPT';

    }, []);

    const redirectToCustomerPortal = async () => {
        const functionRef = httpsCallable<{}, PortalLinkResponse>(functions, 'ext-firestore-stripe-payments-createPortalLink');
        try {
            const { data } = await functionRef({
                returnUrl: window.location.origin, // Redirect the user back to your app after they manage their subscription
            });
            window.location.assign(data.url); // Now TypeScript knows 'data' has a 'url' property
        } catch (error) {
            console.error('Error creating portal link:', error);
        }
    };


    return (

        <div className="flex flex-col min-h-screen">
            <Navbar currentNav="Account" />
            <div className="flex-grow bg-white">
                <div className="mx-auto max-w-6xl py-6 px-4 sm:px-6 lg:px-4 mt-2">
                    <h2 className="text-2xl font-bold mb-12 text-gray-800">Account Information</h2>

                    <div className="mt-4 border-t border-gray-200">
                        <dl className="divide-y divide-gray-200">
                            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                <dt className="text-sm font-medium leading-6 text-gray-900">Full name</dt>
                                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{user?.displayName}</dd>
                            </div>

                            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                <dt className="text-sm font-medium leading-6 text-gray-900">Email address</dt>
                                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{user?.email}</dd>
                            </div>

                            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                <dt className="text-sm font-medium leading-6 text-gray-900">Plan Type</dt>
                                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                    {plan === '7-Day Free Trial' && trialRemaining !== null
                                        ? `7-Day Free Trial (${trialRemaining} days remaining)`
                                        : plan}
                                </dd>
                            </div>

                            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                <dt className="text-sm font-medium leading-6 text-gray-900">Subscription Status</dt>
                                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                    {cancelAtNextPeriod
                                        ? `${subscriptionStatus} (Cancelled at next period)`
                                        : subscriptionStatus}
                                </dd>
                            </div>



                            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                <dt className="text-sm font-medium leading-6 text-gray-900">Sign Up Date</dt>
                                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{user?.metadata.creationTime}</dd>
                            </div>

                            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                <dt className="text-sm font-medium leading-6 text-gray-900">Last Sign-in Date</dt>
                                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{user?.metadata.lastSignInTime}</dd>
                            </div>
                            <button
                                className="mt-4 rounded-md bg-blue-600 text-white px-4 py-2"
                                onClick={redirectToCustomerPortal}
                            >
                                Manage Subscription
                            </button>

                        </dl>
                    </div>
                </div>
            </div>

            <div className="bg-white border-t border-gray-200 mt-auto">
                <div className="mx-auto max-w-7xl py-3 px-4 sm:px-6 lg:px-8 flex justify-between items-center text-sm">
                    <p className="text-gray-500">
                        © 2024 HinterviewGPT. All rights reserved.
                    </p>
                    <div>
                        <a href="/privacy" className="text-indigo-600 hover:text-indigo-900 ml-4">
                            Privacy Policy
                        </a>
                        <a href="/tos" className="text-indigo-600 hover:text-indigo-900 ml-4">
                            Terms of Service
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Account;
