import React, { useEffect, useState } from 'react';
import { auth } from './config/firebase';
import { User } from "firebase/auth";
import { Dialog, Tab } from '@headlessui/react';
import { Bars3Icon, XMarkIcon, MinusSmallIcon, PlusSmallIcon, PlusIcon } from '@heroicons/react/24/outline';
import logo from './assets/_7afa50e7-4fe5-42b5-a782-8293c4c5172e-removebg-preview.png';
import genQuestionScreenshot from './assets/gen_question_screenshot.png'
import aiTutorScreenshot from './assets/ai_tutor_screenshot.png'
import evalScreenshot from './assets/eval_screenshot.png'
import apiKeyScreenshot from './assets/api_key_screenshot.png'
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/react';
import { Firestore, collection, getDocs, query, where, addDoc, onSnapshot } from 'firebase/firestore';
import { CheckIcon } from '@heroicons/react/20/solid'
import { CheckCircleIcon } from '@heroicons/react/20/solid';
import { PlayIcon } from '@heroicons/react/24/solid';
import { CodeBracketIcon, PresentationChartLineIcon, ChartBarIcon } from '@heroicons/react/24/outline';
import logoText from './assets/logo_text.png'
import logoLato from './assets/logo_lato.png'
import { db } from './config/firebase';


const navigation = [
    { name: 'How it works', href: '#' },
    { name: 'FAQ', href: '#' },
    { name: 'Blog', href: '/blog' },
    { name: 'About us', href: '#' },
];

const includedFeatures = [
    'Unlimited interview question generation.',
    'Unlimited access to AI tutor.',
    'Unlimited access to interview evaluation.',
    'Review past questions and evaluations anytime.',
]

const faqs = [
    {
        question: "Is HinterviewGPT just for software engineering interviews?",
        answer: "No! software engineering interviews are just a small subset of the types of interviews that HinterviewGPT can be used for.  HinterviewGPT can generate realistic interview questions for virtually any role.  It can also evaluate the correctness of your solution for virtually any realistic interview question."
    },
    {
        question: "How do I get an OpenAI API key?",
        answer: "To get an OpenAI API key, you need to sign up for an account on the OpenAI platform. You can do so by visiting <a href='https://platform.openai.com/signup' target='_blank' rel='noopener noreferrer' class='text-indigo-600 hover:text-indigo-900'>OpenAI Sign Up Page</a>. Once you have an account, navigate to the <a href='https://platform.openai.com/account/api-keys' target='_blank' rel='noopener noreferrer' class='text-indigo-600 hover:text-indigo-900'>API Keys Section</a> of the OpenAI dashboard. There, you can create and manage your API keys. Make sure to keep your API key secure and do not share it publicly."
    },
    {
        question: "Do you store my OpenAI API key?",
        answer: "HinterviewGPT is designed with your privacy and security in mind. Your OpenAI API key is never stored on our backend servers. Instead, it is securely stored in your browser's local storage, ensuring that only you have access to it. The OpenAI API is called directly from your browser during specific operations, including generating interview questions, using the HinterviewGPT chat, evaluating your submitted solutions, and when you choose to regenerate a solution in the 'Solution' tab on the practice page. This approach ensures that your API key remains secure while allowing HinterviewGPT to provide its full range of features.",
    },
    // {
    //     question: "Is HinterviewGPT free?",
    //     answer: "HinterviewGPT does not directly charge anything; however, when HinterviewGPT calls the OpenAI API with the provided key, OpenAI will charge you a usage-based cost. Charges are based on the model you select. You can monitor your usage by visiting OpenAI's <a href='https://platform.openai.com/account/usage' target='_blank' rel='noopener noreferrer' class='text-indigo-600 hover:text-indigo-900'>usage section</a>.  You can view the pricing for each model on the <a href='https://openai.com/api/pricing/' target='_blank' rel='noopener noreferrer' class='text-indigo-600 hover:text-indigo-900'>OpenAI API pricing</a> page.",
    // },

    {
        question: "Is HinterviewGPT ever wrong?",
        answer: "Yes, HinterviewGPT can be wrong.  It is possible for HinterviewGPT to incorrectly evaluate your solution.  HinterviewGPT's accuracy greatly depends on the model you have selected.  GPT-4o is the model that generally provides the best results.  We are confident that HinterviewGPT is accurate enough to provide a valuable study tool for practicing interview questions.",
    },
    {
        question: "Which OpenAI models does HinterviewGPT use?",
        answer: "HinterviewGPT currently offers three OpenAI models: GPT-4, GPT-4o, and GPT-4o-mini.  Each model has its own strengths and weaknesses.  You can learn more about each model by visiting the <a href='https://beta.openai.com/docs/engines' target='_blank' rel='noopener noreferrer' class='text-indigo-600 hover:text-indigo-900'>OpenAI Model Documentation</a>.",
    },
    {
        question: "What if I already have a question in mind that I want to practice?",
        answer: "This is fine.  Generating a question is only one way to add a question.  You can also add a question manually.  Simply provide the title, body, tags and answer type and begin practicing.",
    },
    {
        question: "Can I use Claude models with HinterviewGPT?",
        answer: "Currently, HinterviewGPT only supports OpenAI models.  However adding models from other providers is on the roadmap.",
    },
];

const features = [


    {
        name: 'Enter OpenAI API Key',
        description: 'HinterviewGPT is built on top of the OpenAI API. Once you sign up, simply enter your OpenAI API key and start using HinterviewGPT. You can create your own OpenAI API key by visiting the OpenAI website. ',
        imageSrc: apiKeyScreenshot,
        imageAlt: 'Printed photo of bag being tossed into the sky on top of grass.',
        bulletPoints: [
            'HinterviewGPT never stores your API key',
            'Only pay for what you use',
            'Use the latest OpenAI models'
        ],
        videoUrl: 'https://www.youtube.com/embed/lMJJHa-mnUA?si=HCJSjYAs0djRv840',
    },
    {
        name: 'Generate an interview question',
        description: 'Simply enter a short description of the general topic of the question you would like to study and HinterviewGPT will generate a question that is relevant to that topic.',
        imageSrc: genQuestionScreenshot,
        imageAlt: 'Printed photo of bag being tossed into the sky on top of grass.',
        bulletPoints: [
            'Specify role, company, and difficulty',
            'Generate diverse questions',
            'Study a wide range of topics'
        ],
        videoUrl: 'https://www.youtube.com/embed/BC4N2eQrl0w?si=gNHPdz1CDLT8oPxm'
    },
    {
        name: 'Practice with your AI tutor',
        description: "Your AI tutor will provide hints and feedback based on your solution so far, helping you improve your answer and learn in the process.",
        imageSrc: aiTutorScreenshot,
        imageAlt: 'Double stitched black canvas hook loop.',
        bulletPoints: [
            'Get hints without the full answer',
            'Feedback to improve your solution',
            'Tailored to your question and answer'
        ],
        videoUrl: 'https://www.youtube.com/embed/8otMyyh9IJg?si=w2PLVdjjFZT7fdQC'
    },
    {
        name: 'Submit your solution',
        description: "After practicing, submit your solution for evaluation. HinterviewGPT will save your solution and provide suggestions.",
        imageSrc: evalScreenshot,
        imageAlt: 'Black canvas body with chrome zipper and key ring.',
        bulletPoints: [
            'Submit for final evaluation',
            'Save past solutions',
            'Review anytime for improvement'
        ],
        videoUrl: 'https://www.youtube.com/embed/dUxvynpS_0g?si=x6iz42TEqtw06uNu'
    },
];

interface Price {
    id: string;  // Add id to represent the price document ID
    currency: string;
    unit_amount: number;
    interval: 'day' | 'week' | 'month' | 'year';
}


interface Product {
    name: string;
    description: string;
    prices: Price[];
}



function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ')
}

const LandingPage = () => {
    const [expanded, setExpanded] = useState<string | false>('');
    const [user, setUser] = useState<User | null>(null);
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
    const [products, setProducts] = useState<Product[]>([]);
    const [isOpen, setIsOpen] = useState(false);
    const [currentImageSrc, setCurrentImageSrc] = useState('');
    const [isVideoOpen, setIsVideoOpen] = useState(false);
    const [currentVideoUrl, setCurrentVideoUrl] = useState<string | null>(null);
    const [email, setEmail] = useState('');
    const [alertMessage, setAlertMessage] = useState('');
    const [alertType, setAlertType] = useState<'success' | 'error' | ''>('');

    const showAlert = (message: string, type: 'success' | 'error') => {
        setAlertMessage(message);
        setAlertType(type);

        // Automatically hide alert after 5 seconds
        setTimeout(() => {
            setAlertMessage('');
            setAlertType('');
        }, 5000);
    };


    const handleWatchVideo = (videoUrl: string) => {
        setCurrentVideoUrl(videoUrl);
        setIsVideoOpen(true);
    };


    useEffect(() => {
        const fetchProducts = async () => {
            const productsCollection = collection(db, 'products');
            const q = query(productsCollection, where('active', '==', true));
            const querySnapshot = await getDocs(q);

            const productsData: Product[] = [];
            for (const productDoc of querySnapshot.docs) {
                const productData = productDoc.data() as Omit<Product, 'prices'>;
                const pricesCollection = collection(productDoc.ref, 'prices');
                const pricesSnapshot = await getDocs(pricesCollection);
                const prices: Price[] = pricesSnapshot.docs.map((priceDoc) => {
                    const priceData = priceDoc.data() as Omit<Price, 'id'>;  // Exclude id
                    return {
                        id: priceDoc.id,  // Manually add the Firestore ID
                        ...priceData,     // Spread the rest of the price data
                    };
                });
                productsData.push({ ...productData, prices });
            }
            setProducts(productsData);
        };

        fetchProducts();
    }, []);


    const handleCheckout = async (priceId: string) => {
        const user = auth.currentUser;

        if (!user) {
            // If the user isn't logged in, redirect them to the login page
            window.location.href = '/auth';
            return;
        }

        const checkoutSessionRef = collection(db, 'customers', user.uid, 'checkout_sessions');

        try {
            const docRef = await addDoc(checkoutSessionRef, {
                price: priceId,
                success_url: `${window.location.origin}/success`, // Redirect to the success page after payment
                cancel_url: window.location.origin,
            });

            // Listen for the session URL from Stripe and redirect the user
            const unsubscribe = onSnapshot(docRef, (snap) => {
                const { error, url } = snap.data()!;
                if (error) {
                    alert(`An error occurred: ${error.message}`);
                }
                if (url) {
                    window.location.assign(url); // Redirect to Stripe Checkout
                }
            });
        } catch (error) {
            console.error('Error creating checkout session:', error);
            alert('An error occurred while creating the checkout session.');
        }
    };

    type AlertType = 'success' | 'error';

    const handleSubscribe = async (email: string, setAlert: (msg: string, type: AlertType) => void) => {
        if (!email) {
            setAlert("Please enter a valid email.", "error");
            return;
        }

        try {
            const mailingListCollection = collection(db, 'mailingList');
            // Check if the email already exists in the mailing list
            const q = query(mailingListCollection, where("email", "==", email));
            const querySnapshot = await getDocs(q);

            if (!querySnapshot.empty) {
                // Email already exists in the collection
                setAlert("This email is already subscribed.", "error");
                return;
            }

            // If email does not exist, add it to the mailing list
            await addDoc(mailingListCollection, { email });
            setAlert("Thank you for subscribing!", "success");
        } catch (error) {
            console.error('Error adding email to mailing list:', error);
            setAlert('An error occurred while subscribing. Please try again.', "error");
        }
    };

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((authUser) => {
            if (authUser) {
                setUser(authUser);
            } else {
                console.log("User is signed out");
                setUser(null);
            }
        });

        return () => unsubscribe();
    }, []);

    useEffect(() => {

        document.title = 'HinterviewGPT - Generate and Practice Interview Questions with AI';

    }, []);

    return (
        <div className=" ">
            <div className="">

                <header className="sticky top-0 z-50 max-w-8xl border border-gray-300 bg-white rounded-2xl mt-2 mx-auto sm:mx-2 lg:mx-4">
                    {/* Navbar */}
                    <nav className="flex items-center sticky justify-between h-16 px-2 lg:px-4" aria-label="Global">
                        {/* Logo */}
                        <div className="flex">
                            <a href="/" className="-m-1">
                                <span className="sr-only">HinterviewGPT</span>
                                <img className="h-8 w-auto" src={logoLato} alt="HinterviewGPT Logo" />
                            </a>
                        </div>

                        {/* Navigation Links */}
                        <div className="hidden lg:flex lg:gap-x-8 ml-auto mr-6">
                            <a href="/#how-it-works" className="text-md font-medium text-gray-700 hover:text-indigo-600 transition">
                                How It Works
                            </a>
                            <a href="/#pricing" className="text-md font-medium text-gray-700 hover:text-indigo-600 transition">
                                Pricing
                            </a>
                            <a href="/#faq" className="text-md font-medium text-gray-700 hover:text-indigo-600 transition">
                                FAQ
                            </a>
                            <a href="/blog" className="text-md font-medium text-gray-700 hover:text-indigo-600 transition">
                                Blog
                            </a>
                        </div>

                        {/* Log in / App Button */}
                        <div className="hidden lg:flex lg:justify-end ml-4">
                            <a
                                href={user ? "/activity" : "/auth"}
                                className="inline-flex items-center px-3 py-1.5 border border-transparent text-sm font-semibold rounded-md text-white bg-indigo-600 hover:bg-indigo-700 transition"
                            >
                                {user ? "App" : "Log In"} <span aria-hidden="true">→</span>
                            </a>
                        </div>

                        {/* Mobile Menu Button */}
                        <div className="flex lg:hidden">
                            <button
                                type="button"
                                className="inline-flex items-center justify-center p-1.5 rounded-md text-gray-700 hover:text-indigo-600 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                                onClick={() => setMobileMenuOpen(true)}
                            >
                                <span className="sr-only">Open main menu</span>
                                {/* Icon: Hamburger Menu */}
                                {/* <MenuIcon className="h-6 w-6" aria-hidden="true" /> */}
                            </button>
                        </div>
                    </nav>
                </header>


            </div>
            {/* Hero Section */}
            <div
                className="relative isolate pt-12 pb-20  text-gray-900  "
            >


                <div className="mx-auto max-w-7xl px-6 sm:px-10 lg:flex lg:items-center lg:gap-x-10">
                    {/* Text content */}
                    <div className="lg:w-2/6">
                        <div className="max-w-lg mr-8">
                            <h1 className="text-5xl font-extrabold tracking-tight sm:text-5xl text-gray-900">
                                Interview Prep Meets
                                <span className="bg-clip-text text-transparent bg-gradient-to-r from-indigo-600 via-purple-500 to-pink-500 animate-pulse">
                                {" "}Generative AI
                                </span>
                            </h1>
                            <p className="mt-6 text-xl leading-8 text-gray-700">
                                HinterviewGPT is the best platform for generating realistic interview questions and practicing them with your very own AI tutor, helping you prepare for your next big opportunity.
                            </p>
                            <div className="mt-10 flex space-x-2">
                                <a
                                    href={user ? "/activity" : "/auth"}
                                    className="inline-flex items-center justify-center px-6 py-3 text-sm font-semibold text-white bg-indigo-600 rounded-full shadow-md hover:bg-indigo-700 transition"
                                >
                                    Start Your Free Trial <span aria-hidden="true">→</span>
                                </a>
                                <a
                                    href="#how-it-works"
                                    className="inline-flex items-center justify-center px-6 py-3 text-sm font-semibold text-indigo-600 bg-white border border-indigo-600 rounded-full shadow-md hover:bg-indigo-50 transition"
                                >
                                    Learn More
                                </a>
                            </div>
                        </div>
                    </div>

                    {/* Video iframe */}
                    <div className="mt-16 lg:mt-0 lg:w-4/6 flex justify-center">
                        <iframe
                            src="https://player.vimeo.com/video/1004924176?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;autoplay=1&amp;muted=1&amp;loop=1"
                            allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
                            title="HinterviewGPT Demo"
                            width="100%"
                            height="490"
                            frameBorder="2"
                            allowFullScreen
                            className="rounded-2xl "
                        ></iframe>
                    </div>
                </div>
            </div>

            {/* How It Works Section */}
            <div id="how-it-works" className="bg-white scroll-mt-16">
                <div className="mx-auto max-w-7xl py-14 sm:px-2 sm:py-12 lg:px-4">
                    <div className="mx-auto max-w-2xl px-4 lg:max-w-none">
                        <div className="max-w-3xl mx-auto text-center">
                            <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">How HinterviewGPT Works</p>
                            <p className="mt-6 text-lg leading-8 text-gray-600">
                                HinterviewGPT is an AI-powered platform for generating and practicing interview questions that is built on top of the OpenAI API.
                            </p>
                        </div>
                        <div className="mt-12 space-y-16">
                            {features.map((feature, index) => (
                                <div key={feature.name} className={`flex justify-center ${index % 2 === 1 ? 'lg:flex-row-reverse' : ''}`}>
                                    {/* Bordered Container with Shadow */}
                                    <div className="border border-gray-300 bg-gray-50 rounded-lg shadow-lg p-6 flex flex-col lg:flex-row lg:items-center lg:gap-x-8 w-full max-w-7xl">
                                        <div className="lg:w-2/5">
                                            <span className="text-indigo-600 text-lg font-semibold">Step {index + 1}</span>
                                            <h3 className="text-2xl font-bold text-gray-900">{feature.name}</h3>
                                            <p className="mt-4 text-lg text-gray-800">{feature.description}</p>
                                            {feature.bulletPoints && (
                                                <ul className="mt-6 space-y-4">
                                                    {feature.bulletPoints.map((bullet, idx) => (
                                                        <li key={idx} className="flex">
                                                            <CheckCircleIcon className="h-6 w-6 text-indigo-600 flex-shrink-0" aria-hidden="true" />
                                                            <span className="ml-3 text-base text-gray-800">{bullet}</span>
                                                        </li>
                                                    ))}
                                                </ul>
                                            )}
                                            {/* Added Button Below Bullet Points */}
                                            <div className="mt-6">
                                                <button
                                                    onClick={() => handleWatchVideo(feature.videoUrl)}
                                                    className="inline-flex items-center px-4 py-2 border border-indigo-600 text-base font-medium rounded-full shadow-sm text-indigo-600 bg-white hover:bg-gray-100"
                                                >
                                                    <PlayIcon className="h-5 w-5 mr-2" aria-hidden="true" />
                                                    Watch How It Works
                                                </button>
                                            </div>
                                        </div>
                                        <div className="mt-10 lg:mt-0 lg:w-3/5">
                                            <img
                                                alt={feature.imageAlt}
                                                src={feature.imageSrc}
                                                className="rounded-md border border-gray-300 w-full cursor-pointer"
                                                onClick={() => {
                                                    setCurrentImageSrc(feature.imageSrc);
                                                    setIsOpen(true);
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <Dialog open={isVideoOpen} onClose={() => setIsVideoOpen(false)} className="relative z-50">
                    <div className="fixed inset-0 bg-black/50" aria-hidden="true" />
                    <div className="fixed inset-0 flex items-center justify-center p-4">
                        <Dialog.Panel className="w-full max-w-5xl">
                            <div className="relative flex justify-center">
                                <button
                                    className="absolute top-4 right-4 text-gray-600 hover:text-gray-800"
                                    onClick={() => setIsVideoOpen(false)}
                                >
                                </button>
                                {currentVideoUrl && (
                                    <iframe
                                        className="w-full h-[654px] rounded-lg"
                                        src={currentVideoUrl}
                                        title="YouTube video player"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                        allowFullScreen
                                    />
                                )}
                            </div>
                        </Dialog.Panel>
                    </div>
                </Dialog>



            </div>


            {/* Who is HinterviewGPT For Section */}
            <div className="relative isolate py-14">


                <div className="mx-auto max-w-7xl px-6 lg:px-8">
                    <div className="max-w-5xl mx-auto text-center">
                        <h2 className="text-4xl font-extrabold text-gray-900">Who is HinterviewGPT For?</h2>
                        <p className="mt-4 text-lg text-gray-600">
                            HinterviewGPT is designed for anyone preparing for an upcoming interview, regardless of industry or role. Whether you're a seasoned professional or just starting your career, our platform helps you practice and improve your interview skills, increasing your chances of landing the job.
                        </p>
                    </div>

                    {/* Grid of Roles */}
                    <div className="mt-16 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8">
                        {/* Card 1 */}
                        <div className="flex flex-col items-center text-center bg-white p-8 rounded-lg shadow hover:shadow-lg transition-shadow duration-300">
                            <CodeBracketIcon className="h-16 w-16 text-indigo-600 mb-4" />
                            <h3 className="text-xl font-semibold text-gray-900">Software Engineers</h3>
                            <p className="mt-2 text-base text-gray-600">
                                Hone your coding interview skills and tackle algorithmic challenges with confidence.
                            </p>
                        </div>
                        {/* Card 2 */}
                        <div className="flex flex-col items-center text-center bg-white p-8 rounded-lg shadow hover:shadow-lg transition-shadow duration-300">
                            <PresentationChartLineIcon className="h-16 w-16 text-indigo-600 mb-4" />
                            <h3 className="text-xl font-semibold text-gray-900">Product Managers</h3>
                            <p className="mt-2 text-base text-gray-600">
                                Master product strategy and cross-functional collaboration topics for your interviews.
                            </p>
                        </div>
                        {/* Card 3 */}
                        <div className="flex flex-col items-center text-center bg-white p-8 rounded-lg shadow hover:shadow-lg transition-shadow duration-300">
                            <ChartBarIcon className="h-16 w-16 text-indigo-600 mb-4" />
                            <h3 className="text-xl font-semibold text-gray-900">Data Analysts</h3>
                            <p className="mt-2 text-base text-gray-600">
                                Prepare for data interpretation and statistical analysis questions to stand out.
                            </p>
                        </div>
                        {/* Card 4 */}
                        <div className="flex flex-col items-center text-center bg-white p-8 rounded-lg shadow hover:shadow-lg transition-shadow duration-300">
                            <div className="flex items-center justify-center h-16 w-16 bg-indigo-100 rounded-full mb-4">
                                <PlusIcon className="h-8 w-8 text-indigo-600" aria-hidden="true" />
                            </div>
                            <h3 className="text-xl font-semibold text-gray-900">And Many More</h3>
                            <p className="mt-2 text-base text-gray-600">
                                No matter your field, HinterviewGPT can help you excel in your interviews.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            {/* Pricing Section */}
            <div id="pricing" className="bg-white py-14 scroll-mt-16">
                <div className="mx-auto max-w-7xl px-6 lg:px-8">
                    <div className="text-center">
                        <h2 className="text-4xl font-extrabold text-gray-900">Pricing</h2>
                        <p className="mt-4 text-lg text-gray-600">
                            HinterviewGPT has one price for all. No hidden fees or tiers.
                        </p>
                    </div>
                    {products.length > 0 && (
                        <div className="mt-12 flex justify-center">
                            {products.map((product, index) => (
                                <div
                                    key={product.name}
                                    className="relative rounded-lg p-8 shadow-lg border border-gray-200 bg-white max-w-lg"
                                >
                                    <h3 className="text-2xl font-bold text-gray-900">{product.name}</h3>
                                    <p className="mt-4 text-base text-gray-600">Bring your own OpenAI API key plan</p>
                                    <p className="mt-6 text-4xl font-extrabold text-gray-900">
                                        ${product.prices[0].unit_amount / 100}
                                        <span className="text-lg font-medium text-gray-500"> / month</span>
                                    </p>
                                    <p className="mt-2 text-sm text-green-600 font-semibold">7-day free trial included</p>
                                    <ul className="mt-8 space-y-4">
                                        {includedFeatures.map((feature) => (
                                            <li key={feature} className="flex items-center">
                                                <CheckIcon className="h-6 w-6 text-indigo-600 flex-shrink-0" aria-hidden="true" />
                                                <span className="ml-3 text-base text-gray-600">{feature}</span>
                                            </li>
                                        ))}
                                    </ul>
                                    <button
                                        onClick={() => handleCheckout(product.prices[0].id)}
                                        className="mt-8 w-full py-3 px-4 rounded-md text-base font-semibold text-white bg-indigo-600 hover:bg-indigo-700 transition duration-300"
                                    >
                                        Start Free Trial
                                    </button>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            </div>


            {/* FAQ Section */}
            <div id="faq" className="py-24 scroll-mt-16">
                <div className="mx-auto max-w-7xl px-6 lg:px-8">
                    <div className="text-center">
                        <h2 className="text-3xl font-extrabold text-gray-900">Frequently Asked Questions</h2>
                    </div>
                    <div className="mt-12 max-w-3xl mx-auto divide-y divide-gray-200">
                        {faqs.map((faq) => (
                            <Disclosure key={faq.question} as="div" className="py-6">
                                {({ open }) => (
                                    <>
                                        <Disclosure.Button className="flex justify-between items-center w-full text-left">
                                            <span className="text-lg font-medium text-gray-900">{faq.question}</span>
                                            {open ? (
                                                <MinusSmallIcon className="w-6 h-6 text-indigo-600" />
                                            ) : (
                                                <PlusSmallIcon className="w-6 h-6 text-indigo-600" />
                                            )}
                                        </Disclosure.Button>
                                        <Disclosure.Panel className="mt-4 text-gray-600">
                                            <p dangerouslySetInnerHTML={{ __html: faq.answer }} />
                                        </Disclosure.Panel>
                                    </>
                                )}
                            </Disclosure>
                        ))}
                    </div>
                </div>

                {/* Image Modal */}
                <Dialog open={isOpen} onClose={() => setIsOpen(false)} className="relative z-50">
                    <div className="fixed inset-0 bg-black/50" aria-hidden="true" />

                    <div className="fixed inset-0 flex items-center justify-center p-4">
                        <Dialog.Panel className="mx-auto max-w-4xl">
                            <div className="relative bg-white rounded-lg shadow-lg">
                                <button
                                    className="absolute top-4 right-4 text-gray-600 hover:text-gray-800"
                                    onClick={() => setIsOpen(false)}
                                >
                                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                </button>
                                <img src={currentImageSrc} alt="Enlarged" className="rounded-lg w-full h-auto" />
                            </div>
                        </Dialog.Panel>
                    </div>
                </Dialog>
            </div>
            {/* Tailwind Alert */}
            {alertMessage && (
                <div className={`fixed top-4 inset-x-0 mx-auto max-w-md px-4 py-3 rounded-md shadow-md text-white ${alertType === 'success' ? 'bg-green-600' : 'bg-red-600'}`}>
                    <p>{alertMessage}</p>
                </div>
            )}

            {/* Mailing List Subscription Section */}
            <div className="bg-indigo-600 py-16 px-6 text-white">
                <div className="max-w-4xl mx-auto text-center">
                    <h2 className="text-3xl font-extrabold mb-4">Subscribe to Our Mailing List</h2>
                    <p className="mb-8 text-lg">Stay updated with the latest features and news from HinterviewGPT.</p>
                    <div className="flex justify-center">
                        <input
                            type="email"
                            className="w-2/3 p-3 rounded-l-lg text-gray-800"
                            placeholder="Enter your email address"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        <button
                            onClick={() => handleSubscribe(email, showAlert)}
                            className="bg-indigo-800 px-5 py-3 rounded-r-lg text-white font-semibold hover:bg-indigo-700 transition"
                        >
                            Subscribe
                        </button>
                    </div>
                </div>
            </div>
            {/* Footer */}
            <footer className="bg-gray-900">
                <div className="mx-auto max-w-7xl py-12 px-6 lg:px-8">
                    <div className="flex flex-col md:flex-row justify-between items-center">
                        <p className="text-center md:text-left text-sm text-gray-400">© 2024 HinterviewGPT. All rights reserved.</p>
                        <div className="mt-4 md:mt-0 flex space-x-6">
                            <a href="/privacy" className="text-gray-400 hover:text-white transition">
                                Privacy Policy
                            </a>
                            <a href="/tos" className="text-gray-400 hover:text-white transition">
                                Terms of Service
                            </a>
                            <a href="/contact" className="text-gray-400 hover:text-white transition">
                                Contact Us
                            </a>
                        </div>
                    </div>
                    <div className="mt-8 flex justify-center space-x-6">
                        {/* Social Media Icons */}
                        {/* <a href="#" className="text-gray-400 hover:text-white transition">
                    <span className="sr-only">Facebook</span>
                    <FacebookIcon className="h-6 w-6" aria-hidden="true" />
                </a>
                <a href="#" className="text-gray-400 hover:text-white transition">
                    <span className="sr-only">Twitter</span>
                    <TwitterIcon className="h-6 w-6" aria-hidden="true" />
                </a>
                <a href="#" className="text-gray-400 hover:text-white transition">
                    <span className="sr-only">LinkedIn</span>
                    <LinkedInIcon className="h-6 w-6" aria-hidden="true" />
                </a> */}
                        {/* Add more social icons as needed */}
                    </div>
                </div>
            </footer>
        </div>


    );
};

export default LandingPage;