import React, { useState, useEffect } from 'react';
import { auth } from './config/firebase';
import { User } from "firebase/auth";
import Navbar from './navbar';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import { Dialog } from '@headlessui/react';
import logo from './assets/_7afa50e7-4fe5-42b5-a782-8293c4c5172e-removebg-preview.png';
import logoText from './assets/logo_text.png'
import logoLato from './assets/logo_lato.png'


const navigation = [
    { name: 'How it works', href: '#' },
    { name: 'FAQ', href: '#' },
    { name: 'Blog', href: '#' },
    { name: 'About us', href: '#' },
];



const TermsOfService = () => {
    const [user, setUser] = useState<User | null>(null);
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((authUser) => {
            if (authUser) {
                setUser(authUser);
            } else {
                console.log("User is signed out");
                setUser(null);
            }
        });

        return () => unsubscribe();
    }, []);

    useEffect(() => {

        document.title = 'Terms of Service | HinterviewGPT';

    }, []);

    return (
        <div className="bg-white">
            <header className="sticky top-0 z-50 max-w-8xl border border-gray-300 bg-white rounded-2xl mt-2 mx-auto sm:mx-2 lg:mx-4">
                    {/* Navbar */}
                    <nav className="flex items-center justify-between h-16 px-2 lg:px-4" aria-label="Global">
                        {/* Logo */}
                        <div className="flex">
                            <a href="/" className="-m-1">
                                <span className="sr-only">HinterviewGPT</span>
                                <img className="h-8 w-auto" src={logoLato} alt="HinterviewGPT Logo" />
                            </a>
                        </div>

                        {/* Navigation Links */}
                        <div className="hidden lg:flex lg:gap-x-6 ml-auto">
                            <a href="/#how-it-works" className="text-md font-medium text-gray-700 hover:text-indigo-600 transition">
                                How It Works
                            </a>
                            <a href="/#pricing" className="text-md font-medium text-gray-700 hover:text-indigo-600 transition">
                                Pricing
                            </a>
                            <a href="/#faq" className="text-md font-medium text-gray-700 hover:text-indigo-600 transition">
                                FAQ
                            </a>
                            <a href="/blog" className="text-md font-medium text-gray-700 hover:text-indigo-600 transition">
                                Blog
                            </a>
                        </div>

                        {/* Log in / App Button */}
                        <div className="hidden lg:flex lg:justify-end ml-4">
                            <a
                                href={user ? "/activity" : "/auth"}
                                className="inline-flex items-center px-3 py-1.5 border border-transparent text-sm font-semibold rounded-md text-white bg-indigo-600 hover:bg-indigo-700 transition"
                            >
                                {user ? "App" : "Log In"} <span aria-hidden="true">→</span>
                            </a>
                        </div>

                        {/* Mobile Menu Button */}
                        <div className="flex lg:hidden">
                            <button
                                type="button"
                                className="inline-flex items-center justify-center p-1.5 rounded-md text-gray-700 hover:text-indigo-600 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                                onClick={() => setMobileMenuOpen(true)}
                            >
                                <span className="sr-only">Open main menu</span>
                                {/* Icon: Hamburger Menu */}
                                {/* <MenuIcon className="h-6 w-6" aria-hidden="true" /> */}
                            </button>
                        </div>
                    </nav>
                </header>

            <main className="p-4 lg:px-28">
                <h1 className="text-2xl font-bold mb-4">Terms of Service</h1>

                <section className="mb-6">
                    <h2 className="text-xl font-semibold">Introduction</h2>
                    <p>
                        Welcome to HinterviewGPT. By using our services, you agree to these Terms of Service. Please read them carefully.
                    </p>
                </section>

                <section className="mb-6">
                    <h2 className="text-xl font-semibold">Use of Services</h2>
                    <p>
                        HinterviewGPT grants you a limited, non-exclusive, non-transferable, and revocable license to use our services in accordance with these Terms. You agree not to use our services for any unlawful or prohibited activities.
                    </p>
                </section>

                <section className="mb-6">
                    <h2 className="text-xl font-semibold">User Content</h2>
                    <p>
                        By submitting content through HinterviewGPT, including questions, solutions, and other data, you grant us a license to use, modify, and display this content solely for the purpose of providing our services to you. You retain ownership of all your content.
                    </p>
                    <p>
                        You are responsible for ensuring that your content does not violate any laws or infringe on the rights of third parties. We reserve the right to remove any content that violates these terms.
                    </p>
                </section>

                <section className="mb-6">
                    <h2 className="text-xl font-semibold">Account Security</h2>
                    <p>
                        You are responsible for maintaining the security of your account and for all activities that occur under your account. You agree to notify us immediately of any unauthorized use of your account.
                    </p>
                </section>

                <section className="mb-6">
                    <h2 className="text-xl font-semibold">Limitation of Liability</h2>
                    <p>
                        To the fullest extent permitted by law, HinterviewGPT shall not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible losses, resulting from your use of our services.
                    </p>
                </section>

                <section className="mb-6">
                    <h2 className="text-xl font-semibold">Termination</h2>
                    <p>
                        We reserve the right to suspend or terminate your access to our services at any time, with or without notice, for conduct that we believe violates these Terms of Service or is harmful to other users of our services, us, or third parties, or for any other reason.
                    </p>
                </section>

                <section className="mb-6">
                    <h2 className="text-xl font-semibold">Governing Law</h2>
                    <p>
                        These Terms of Service shall be governed by and construed in accordance with the laws of the jurisdiction in which HinterviewGPT operates, without regard to its conflict of law principles.
                    </p>
                </section>

                <section className="mb-6">
                    <h2 className="text-xl font-semibold">Changes to These Terms</h2>
                    <p>
                        We may update these Terms of Service from time to time. If we make material changes, we will provide you with notice through our services or by other means, to give you the opportunity to review the changes before they become effective. By continuing to use our services after the changes come into effect, you agree to be bound by the revised terms.
                    </p>
                </section>

                <section className="mb-6">
                    <h2 className="text-xl font-semibold">Contact Us</h2>
                    <p>
                        If you have any questions or concerns about these Terms of Service, please contact us at support@hinterviewgpt.com.
                    </p>
                </section>
            </main>
            <div className="bg-white border-t border-gray-200 mt-auto">
                <div className="mx-auto max-w-7xl py-3 px-4 sm:px-6 lg:px-8 flex justify-between items-center text-sm">
                    <p className="text-gray-500">
                        © 2024 HinterviewGPT. All rights reserved.
                    </p>
                    <div>
                        <a href="/privacy" className="text-indigo-600 hover:text-indigo-900 ml-4">
                            Privacy Policy
                        </a>
                        <a href="/tos" className="text-indigo-600 hover:text-indigo-900 ml-4">
                            Terms of Service
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TermsOfService;
