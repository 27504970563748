import React, { useState, useRef } from 'react';
import { PlayIcon, PauseIcon, ArrowPathIcon, ArrowLeftIcon, ArrowLeftCircleIcon } from '@heroicons/react/20/solid';
import { ClockIcon } from '@heroicons/react/24/outline';
import { Tooltip } from '@mui/material';



const Timer: React.FC = () => {
  const [time, setTime] = useState(0);
  const [isRunning, setIsRunning] = useState(false);
  const intervalRef = useRef<NodeJS.Timeout | null>(null);
  const [showTimer, setShowTimer] = useState(false);

  const formatTime = (time: number): string => {
    const hours = Math.floor(time / 3600);
    const minutes = Math.floor((time % 3600) / 60);
    const seconds = Math.floor(time % 60);

    const formattedHours = String(hours).padStart(2, '0');
    const formattedMinutes = String(minutes).padStart(2, '0');
    const formattedSeconds = String(seconds).padStart(2, '0');

    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  };

  const startTimer = () => {
    setIsRunning(true);
    intervalRef.current = setInterval(() => {
      setTime((prevTime) => prevTime + 1);
    }, 1000);
  };

  const pauseTimer = () => {
    setIsRunning(false);
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }
  };

  const resetTimer = () => {
    setTime(0);
    setIsRunning(false);
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }
  };

  const goBack = () => {
    setShowTimer(false);
    resetTimer(); // Reset timer when closing
  };

  const toggleTimer = () => {
    setShowTimer(!showTimer);
  };

  return (
    <div className="max-w-md mx-auto p-2 bg-gray-100">
      {showTimer ? (
        <div className="flex items-center justify-center h-full">
          <div className="flex items-center">
            <Tooltip title="Back" PopperProps={{
              modifiers: [
                {
                  name: 'offset',
                  options: {
                    offset: [0, -6], // Change this to control the offset
                  },
                },
              ],
            }}>
              <button
                className="bg-gray-200 text-gray-700 px-2 py-2 mr-2 rounded-lg hover:bg-gray-300"
                onClick={goBack}
              >
                <ArrowLeftIcon className="h-4 w-4 text-gray-500" />
              </button>
            </Tooltip>
            <div>
              {!isRunning ? (
                <Tooltip title="Start" PopperProps={{
                  modifiers: [
                    {
                      name: 'offset',
                      options: {
                        offset: [0, -6], // Change this to control the offset
                      },
                    },
                  ],
                }}>
                  <button
                    className="bg-gray-200 text-gray-700 px-2 py-2 mr-2 rounded-lg hover:bg-gray-300"
                    onClick={startTimer}
                  >
                    <PlayIcon className="h-4 w-4 text-gray-500" />
                  </button>
                </Tooltip>
              ) : (
                <Tooltip title="Pause" PopperProps={{
                  modifiers: [
                    {
                      name: 'offset',
                      options: {
                        offset: [0, -6], // Change this to control the offset
                      },
                    },
                  ],
                }}>
                  <button
                    className="bg-gray-200 text-gray-700 px-2 py-2 mr-2 rounded-lg hover:bg-gray-300"
                    onClick={pauseTimer}
                  >
                    <PauseIcon className="h-4 w-4 text-gray-500" />
                  </button>
                </Tooltip>
              )}
            </div>
            <div className="w-24 text-xl font-semibold">
              {formatTime(time)}
            </div>
            <div>
              <Tooltip title="Reset" PopperProps={{
                modifiers: [
                  {
                    name: 'offset',
                    options: {
                      offset: [0, -6], // Change this to control the offset
                    },
                  },
                ],
              }}>
                <button
                  className="bg-gray-200 text-gray-700 px-2 py-2 mr-2 rounded-lg hover:bg-gray-300"
                  onClick={resetTimer}
                >
                  <ArrowPathIcon className="h-4 w-4 text-gray-500" />
                </button>
              </Tooltip>
            </div>
          </div>
        </div>
      ) : (
        <Tooltip title="Timer" PopperProps={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, -6], // Change this to control the offset
              },
            },
          ],
        }}>
          <button
            className="bg-gray-200 text-gray-700 px-2 py-2 rounded-lg hover:bg-gray-300"
            onClick={toggleTimer}
          >
            <ClockIcon className="h-5 w-5 text-gray-500" />
          </button>
        </Tooltip>
      )}
    </div>
  );
};

export default Timer;
