import React, { useState, useEffect, ChangeEvent, useRef } from 'react';
import OpenAI from 'openai';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import { TextField, Button, DialogContent, DialogActions, Snackbar, Alert } from '@mui/material';
import { ArrowUpCircleIcon, StopCircleIcon, SparklesIcon } from '@heroicons/react/24/solid';
import { ExclamationTriangleIcon } from '@heroicons/react/24/solid';
import { IconMessage, IconEdit, IconX } from '@tabler/icons-react';
import { Tooltip } from '@mui/material';
import { auth } from "./config/firebase";
import { User } from "firebase/auth";
import { IconMaximize, IconMinimize, IconChevronUp, IconChevronDown } from '@tabler/icons-react';
import logo from './assets/_7afa50e7-4fe5-42b5-a782-8293c4c5172e-removebg-preview.png'
import ReactMarkdown from 'react-markdown';
import remarkMath from 'remark-math';
import rehypeKatex from 'rehype-katex';
import 'katex/dist/katex.min.css';
import loadingGif from './assets/loading.gif'
import { Dialog, DialogPanel, DialogTitle, Transition, TransitionChild } from '@headlessui/react';
import rehypeHighlight from 'rehype-highlight';
import 'highlight.js/styles/atom-one-dark.css';
import 'prismjs/themes/prism-okaidia.css';
import rehypePrism from 'rehype-prism-plus'


interface ChatUIProps {
  messages: any[];
  setMessages: React.Dispatch<React.SetStateAction<any[]>>;
  codeContent: string;
  TextContent: string;
  answerType: string;
  leetCodeQuestion: string;
  leetCodeAnswer: string;
  questionType: string;
  additionalPrompt: string;
  isChatMaximized: boolean;
  isChatFolded: boolean;
  setIsChatMaximized: React.Dispatch<React.SetStateAction<boolean>>;
  minimizeChatPane: () => void;
  unminimizeChatPane: () => void;
}

const ChatUI: React.FC<ChatUIProps> = ({ messages, setMessages, codeContent, TextContent, answerType, leetCodeQuestion, leetCodeAnswer, questionType, additionalPrompt, isChatMaximized, isChatFolded, setIsChatMaximized, minimizeChatPane, unminimizeChatPane }) => {


  const [inputText, setInputText] = useState<string>("");
  const [tempPrompt, setTempPrompt] = useState<string>('');
  const messagesContainerRef = useRef<HTMLDivElement | null>(null);
  const [promptDialogOpen, setPromptDialogOpen] = useState(false);
  const [errorSnackbarOpen, setErrorSnackbarOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [user, setUser] = useState<User | null>(null);
  //const [modelName, setModelName] = useState<string>(() => localStorage.getItem('hinterviewGpt_modelName') || 'gpt-3.5-turbo-0125');
  const [loading, setLoading] = useState(false);
  const [startedMessage, setStartedMessage] = useState(false);
  const [loadingText, setLoadingText] = useState('');
  const [apiKeyDialogOpen, setApiKeyDialogOpen] = useState(false);


  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        // User is signed in.
        setUser(authUser);
      } else {
        // No user is signed in.
        setUser(null);
      }
    });

    return () => unsubscribe(); // Unsubscribe on unmount
  }, []);

  useEffect(() => {
    let interval: NodeJS.Timeout;
    if (loading) {
      interval = setInterval(() => {
        setLoadingText((prev) => {
          if (prev.length < 5) {
            return prev + '.';
          }
          return '';
        });
      }, 250); // Adjust the speed of the cycle by changing the interval time
    } else {
      setLoadingText('');
    }
    return () => clearInterval(interval);
  }, [loading]);

  const handleErrorSnackbarClose = () => {
    setErrorSnackbarOpen(false);
  };

  const handleClosePromptDialog = () => {
    setTempPrompt('');
    setPromptDialogOpen(false);
  };


  const handleInputTextChange = (e: ChangeEvent<HTMLInputElement>) => {
    setInputText(e.target.value);
  };

  const preprocessLaTeX = (content: string) => {
    // Replace block-level LaTeX delimiters \[ \] with $$ $$

    const blockProcessedContent = content.replace(
      /\\\[(.*?)\\\]/gs,
      (_, equation) => `$$${equation}$$`,
    );
    // Replace inline LaTeX delimiters \( \) with $ $
    const inlineProcessedContent = blockProcessedContent.replace(
      /\\\((.*?)\\\)/gs,
      (_, equation) => `$${equation}$`,
    );
    return inlineProcessedContent;
  };

  const MarkdownViewer = ({ markdownContent }: { markdownContent: string }) => {
    return <ReactMarkdown
      // className="custom-html-content"
      children={preprocessLaTeX(markdownContent)}
      remarkPlugins={[remarkMath]}
      rehypePlugins={[rehypeKatex, rehypePrism]}
    />
  };

  const RenderHTMLContent: React.FC<{ content: string }> = ({ content }) => {
    return <div className="custom-html-content" dangerouslySetInnerHTML={{ __html: content }} />;
  };


  function stripHtmlTagsWithDOMParser(input: string): string {
    const doc = new DOMParser().parseFromString(input, 'text/html');
    return doc.body.textContent || "";
  }


  const handleSendMessage = async () => {
    const userId = user?.uid || '';
    const apiKey = localStorage.getItem(`hinterviewGpt_openAi_apiKey_${userId}`);
    const isApiKeyActivated = localStorage.getItem(`hinterviewGPt_apiKey_isActivated_${userId}`) === 'true';
    const latestModelName = localStorage.getItem(`hinterviewGpt_modelName_${userId}`);

    if (!isApiKeyActivated) {
      console.error('API key is not activated.');
      setErrorMessage('API key is not activated.');
      setApiKeyDialogOpen(true);
      return;
    }



    if (!apiKey) {
      console.error('API key is not available.');
      setErrorMessage('API key is not available.');
      setApiKeyDialogOpen(true);
      return;
    }

    if (!latestModelName) {
      console.error('Model not recognized.');
      setErrorMessage('Currently selected model not recognized.');
      setApiKeyDialogOpen(true);
      return;
    }

    try {
      const userId = auth.currentUser?.uid;
      if (inputText.trim() !== "" && userId) {
        setLoading(true);
        setStartedMessage(true);
        // console.log(messages)

        const contentToSave = answerType === 'Code' ? codeContent : TextContent;
        const userMessage = inputText;
        const updatedMessages = messages.length === 2
          ? [
            ...messages,
            { role: 'system', content: `Question: ${leetCodeQuestion}` },
            additionalPrompt ? { role: 'system', content: additionalPrompt } : null,
            { role: 'system', content: `User Solution: ${contentToSave}` },
            { role: 'user', content: userMessage },
          ].filter(Boolean)
          : [
            ...messages,
            // { role: 'system', content: `Question: ${leetCodeQuestion}` },
            { role: 'system', content: `Code Content: ${contentToSave}` },
            { role: 'user', content: userMessage },
          ];

        setMessages(updatedMessages);
        setInputText("");

        const openai = new OpenAI({
          apiKey: apiKey,
          dangerouslyAllowBrowser: true
        });


        console.log(updatedMessages)
        let assistantResponse = '';
        const chatCompletion = await openai.chat.completions.create({
          messages: updatedMessages,
          model: latestModelName,//localStorage.getItem('selectedChatGPTVersion') || 'gpt-3.5-turbo',
          stream: true
        });


        let iterationCount = 0;

        for await (const part of chatCompletion) {
          setStartedMessage(false);
          assistantResponse += part.choices[0]?.delta?.content || '';
          if (iterationCount === 0) {
            setMessages((prevMessages) => [...prevMessages, { role: 'assistant', content: assistantResponse }]);
          } else {
            setMessages((prevMessages) => [...prevMessages.slice(0, -1), { role: 'assistant', content: assistantResponse }]);
          }
          iterationCount++;
        }


        setLoading(false);

        if (messagesContainerRef.current) {
          messagesContainerRef.current.scrollTop = messagesContainerRef.current.scrollHeight;
        }

      }
    } catch (error: any) {
      console.error('Error in handleSendMessage:', error);
      setLoading(false);
      setErrorMessage(error instanceof Error ? error.message : 'An error occurred while sending the message. Please try again.');
      setErrorSnackbarOpen(true);
    }
  };

  useEffect(() => {
    if (messagesContainerRef.current) {
      messagesContainerRef.current.scrollTop = messagesContainerRef.current.scrollHeight;
    }
  }, [messages]);

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleSendMessage();
    }
  };

  const handleClearConversation = () => {
    // Clear all messages except the first two
    setMessages([
      messages[0], // System prompt
      messages[1], // Initial assistant message
    ]);
  };

  const handleMaximizeClick = () => {
    setIsChatMaximized(true);
  };
  const handleMinimizeClick = () => {
    setIsChatMaximized(false);
  };

  return (
    <div className="chat-ui bg-gray-100" style={{ paddingTop: '4px', paddingRight: '7px', paddingLeft: '4px', paddingBottom: '2px', }}>
      <div className="border-b border-gray-200" style={{
        display: 'flex', alignItems: 'center', paddingBottom: '2px', paddingTop: '3px', backgroundColor: '#fafafa', position: 'sticky', top: '0', borderTopLeftRadius: '10px', borderTopRightRadius: '10px', borderLeft: '1px solid #dadada',
        borderRight: '1px solid #dadada',
        borderTop: '1px solid #dadada',
      }}>
        <div className="font-medium text-sm" style={{ display: 'flex', alignItems: 'center', fontWeight: 'bold', fontSize: '15px' }}>
          <IconMessage className="mr-1 ml-2 mt-1 mb-1 inline-block text-blue-500" style={{ width: '20px', height: '20px' }} /> HinterviewGPT Chat
        </div>
        <div className="cursor-pointer text-gray-500 hover:text-gray-900" style={{
          display: 'flex', alignItems: 'center',
          marginLeft: 'auto', marginRight: '0px'
        }}>
          <div
            className="cursor-pointer hover:bg-gray-200 hover:text-gray-900 p-1 rounded flex items-center justify-center mr-1 ml-2"
            onClick={handleClearConversation}
          >
            <Tooltip title="New Chat">

              <IconEdit className=" w-5 h-5 text-gray-500" />
            </Tooltip>
          </div>
          {!isChatMaximized && (
            <>
              {isChatFolded ? (

                <div
                  className="cursor-pointer hover:bg-gray-200 hover:text-gray-900 p-1 rounded flex items-center justify-center mr-1"
                  onClick={unminimizeChatPane}
                >
                  <Tooltip title="Unfold">
                    <IconChevronDown className="w-5 h-5 text-gray-500 " />
                  </Tooltip>
                </div>
              ) : (

                <div
                  className="cursor-pointer hover:bg-gray-200 hover:text-gray-900 p-1 rounded flex items-center justify-center mr-1"
                  onClick={minimizeChatPane}            >
                  <Tooltip title="Fold">
                    <IconChevronUp className="w-5 h-5 text-gray-500 " />
                  </Tooltip>
                </div>
              )}
            </>
          )}

          {!isChatMaximized && (
            <div
              className="cursor-pointer hover:bg-gray-200 hover:text-gray-900 p-1 rounded flex items-center justify-center mr-1"
              onClick={handleMaximizeClick}
            >
              <Tooltip title="Maximize" >
                <IconMaximize className="w-5 h-5 text-gray-500" />
              </Tooltip>
            </div>
          )}
          {isChatMaximized && (
            <div
              className="cursor-pointer hover:bg-gray-200 hover:text-gray-900 p-1 rounded flex items-center justify-center mr-1"
              onClick={handleMinimizeClick}
            >
              <Tooltip title="Minimize" >
                <IconMinimize className="w-5 h-5 text-gray-500 " />
              </Tooltip>
            </div>
          )}
        </div>
      </div>
      <div className="messages" ref={messagesContainerRef} style={{ backgroundColor: 'white', borderLeft: '1px solid #dadada', borderRight: '1px solid #dadada' }}>



        {messages.map((message, index) => {
          let messageClass = 'message';
          if (message.role === 'user') {
            messageClass += ' user';
          } else if (message.role === 'assistant') {
            messageClass += ' ai';
          }

          if (message.role !== 'system') {
            return (
              <div key={index} style={{
                marginLeft: '0px', marginTop: '10px', display: 'block', paddingRight: isChatMaximized ? '150px' : '20px',
                paddingLeft: isChatMaximized ? '150px' : '20px',
              }}>
                {message.role === 'assistant' && messageClass.includes('ai') && (
                  <div className="ai-message-2" style={{ display: 'flex', alignItems: 'flex-start', marginBottom: '0px' }}>
                    <img
                      src={logo}
                      style={{
                        borderRadius: '50%', // Make the image circular
                        border: '1px solid #dadada', // Add a border for a better look
                        marginLeft: '2px',
                        marginRight: '10px',
                        marginBottom: '0px',
                        marginTop: '1px',
                        objectFit: 'cover', // Ensure the image covers the entire circular area
                        width: isChatMaximized ? '35px' : '30px',
                        height: isChatMaximized ? '35px' : '30px',
                      }}
                    />
                    <div className="ai-message-content-2" style={{
                      display: 'block', // Ensure vertical stacking of content within this block
                      overflowX: message.content.includes('<pre>') ? 'auto' : 'hidden', // Allow scrolling if <pre> is used
                      whiteSpace: message.content.includes('<pre>') ? 'pre' : 'normal', // Preserve whitespace for <pre>
                      wordWrap: 'break-word', // Ensure words break if needed
                      width: '100%',
                      // marginBottom: '40px',

                    }}>
                      <MarkdownViewer markdownContent={message.content} />
                    </div>
                  </div>

                )}

                {message.role !== 'assistant' && (
                  <div className="ai-message" style={{ marginLeft: 'auto', marginRight: '0', marginTop: '20px', marginBottom: '20px', textAlign: 'right' }}>
                    <div
                      className="ai-message-content"
                      style={{
                        marginLeft: '40%',
                        marginBottom: '0px',
                        textAlign: 'left',  // Ensure the text inside is left-aligned
                        display: 'inline-block' // Ensure the container doesn't stretch full width
                      }}
                    >
                      <div style={{ display: 'inline-block', backgroundColor: '#f0f0f0', padding: '7px', borderRadius: '40px', marginRight: '10px' }}>
                        <div style={{ marginRight: '10px', marginLeft: '10px' }}>
                          <MarkdownViewer markdownContent={message.content} />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            );
          }
          return null;
        })}

        {startedMessage &&
          <div className="ai-message-2">

            <div className="ai-message-2" style={{ display: 'flex', alignItems: 'flex-start', marginLeft: isChatMaximized ? '150px' : '20px', }}>
              <img
                src={logo}
                style={{
                  borderRadius: '50%', // Make the image circular
                  border: '1px solid #dadada', // Add a border for a better look
                  marginLeft: '2px',
                  marginRight: '10px',
                  marginBottom: '0px',
                  marginTop: '1px',
                  objectFit: 'cover', // Ensure the image covers the entire circular area
                  width: isChatMaximized ? '35px' : '30px',
                  height: isChatMaximized ? '35px' : '30px',
                }}
              />
              {loadingText}
            </div>
          </div>
        }

        {/* {messages.length > 2 && !loading && (
          <div className="message clear-conversation" onClick={handleClearConversation}>
            <span className="clear-link">New Conversation</span>
          </div>
        )} */}
      </div>

      <div className="w-full flex flex-col items-center justify-center bg-white border-gray-300 border-l border-r border-b" style={{ borderBottomLeftRadius: '10px', borderBottomRightRadius: '10px' }}>
        <div className="relative w-11/12" style={{
          paddingRight: isChatMaximized ? '100px' : '0px',
          paddingLeft: isChatMaximized ? '100px' : '0px',
        }}>
          <input
            type="text"
            value={inputText}
            onChange={handleInputTextChange}
            placeholder="Message HinterviewGPT"
            onKeyPress={handleKeyPress}
            className="w-full h-12 p-6 rounded-full pr-14 mb-2"
            style={{ backgroundColor: '#f4f4f4', outline: 'none' }}
          />
          <button
            onClick={handleSendMessage}
            className="absolute right-2 top-1/2 transform -translate-y-1/2 p-1 focus:outline-none"
            disabled={loading}  // Disable the button when loading
          >
            <ArrowUpCircleIcon
              className={`h-10 w-10 text-gray-500 mb-2 ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}
              style={{ marginRight: isChatMaximized ? '100px' : '0px' }}
            />
          </button>

        </div>
      </div>

      <Snackbar
        open={errorSnackbarOpen}
        autoHideDuration={6000}
        onClose={handleErrorSnackbarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert severity="error" onClose={handleErrorSnackbarClose}>
          {errorMessage.startsWith("400 This model's maximum context length") ? (
            <span>
              Maximum conversation length reached. Please click "New Conversation" at the bottom of the messages to continue.
            </span>
          ) : (
            <span>
              {errorMessage}
            </span>
          )}
        </Alert>
      </Snackbar>

      <Transition show={apiKeyDialogOpen}>
        <Dialog
          className="relative z-10"
          onClose={() => setApiKeyDialogOpen(false)}
        >
          <TransitionChild
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </TransitionChild>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <TransitionChild
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <DialogPanel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                  <div className="absolute top-0 right-0 pt-4 pr-4">
                    <button
                      type="button"
                      className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      onClick={() => setApiKeyDialogOpen(false)}
                    >
                      <span className="sr-only">Close</span>
                      <IconX className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                  <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                    <div className="sm:flex sm:items-start">
                      <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                        <ExclamationTriangleIcon
                          className="h-6 w-6 text-red-600"
                          aria-hidden="true"
                        />
                      </div>
                      <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                        <DialogTitle
                          as="h3"
                          className="text-base font-semibold leading-6 text-gray-900"
                        >
                          OpenAI API Key Error
                        </DialogTitle>
                        <div className="mt-2 mb-2">
                          <p className="text-sm text-gray-500">
                            It looks like the OpenAI API key has not been activated.
                            Set the API key in the navbar and activate it.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </DialogPanel>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </Transition>


    </div>

  );
};

export default ChatUI;
