import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import logoText from './assets/logo_text.png'
import { Dialog } from '@headlessui/react';
import { User } from "firebase/auth";
import { auth } from './config/firebase';
import logo from './assets/_7afa50e7-4fe5-42b5-a782-8293c4c5172e-removebg-preview.png';
import { XMarkIcon } from '@heroicons/react/24/outline';
import ReactMarkdown from 'react-markdown';
import logoLato from './assets/logo_lato.png'


// Define the type for a blog post
type Post = {
    id: number;
    title: string;
    href: string;
    description: string;
    imageUrl: string;
    date: string;
    datetime: string;
    content: string;
    category: {
        title: string;
        href: string;
    };
};

const navigation = [
    { name: 'How it works', href: '#' },
    { name: 'FAQ', href: '#' },
    { name: 'Blog', href: '#' },
    { name: 'About us', href: '#' },
];


const posts: Post[] = [
    {
        id: 1,
        title: 'The advantages of hint-based interview prep',
        href: '#',
        description: 'Investigating why hint-based interview prep is a better way to prepare for technical interviews.',
        imageUrl: 'https://images.unsplash.com/photo-1496128858413-b36217c2ce36?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=3603&q=80',
        date: 'Sept 17, 2024',
        datetime: '2024-09-17',
        content: `
        <p>Ever found yourself staring at a complex coding problem for hours, feeling the frustration mount as every attempted solution falls flat? You’re not alone. Imagine this scenario: it’s late at night, and you’re diligently preparing for an upcoming technical interview. You tackle a challenging practice problem, and after several unsuccessful attempts, you hit a wall. Frustrated and out of ideas, you cave in and search for the answer online. Suddenly, you’re faced with a solution that makes sense only after hours of piecing it together. Sound familiar? We've all been there.</p>

<h2>The Struggle with Traditional Study Methods</h2>

<p>Traditional study methods often encourage us to seek out answers directly when we encounter difficult problems. While this might provide immediate relief, it can hinder the deep understanding necessary for mastering complex concepts. Simply looking up answers can lead to passive learning, where information is absorbed without truly grasping the underlying principles.</p>

<h2>Why Hint-Based Preparation Makes a Difference</h2>

<p>Hint-based interview preparation offers a more effective alternative. Instead of jumping straight to the solution, hints guide you toward the answer, encouraging active problem-solving and critical thinking. This method fosters a deeper comprehension of the material and enhances your ability to tackle similar challenges in the future.</p>

<h3>1. Enhances Problem-Solving Skills</h3>

<p>Hints stimulate your mind to think creatively and explore different approaches. According to a study published in the <em>Journal of Educational Psychology</em>, students who received hints during problem-solving tasks demonstrated higher levels of understanding and retention compared to those who were simply provided with answers (VanLehn, 2011).</p>

<h3>2. Encourages Active Learning</h3>

<p>Active learning involves engaging with the material, asking questions, and seeking out solutions independently. Research from <em>Active Learning in Higher Education</em> highlights that students who engage in active learning strategies, such as hint-based studying, perform better academically and retain information longer than those who rely on passive methods (Freeman et al., 2014).</p>

<h3>3. Reduces Cognitive Overload</h3>

<p>When you encounter a difficult problem, the immediate impulse might be to find the answer to alleviate the frustration. However, this can lead to cognitive overload as you try to process and understand the solution without having worked through the problem yourself. Hint-based approaches break down the problem into manageable steps, reducing the mental strain and making the learning process more efficient (Sweller, 2011).</p>

<h3>4. Builds Confidence and Independence</h3>

<p>By relying on hints rather than direct answers, you develop the confidence to tackle problems on your own. This independence is crucial during interviews, where the ability to navigate challenges without external assistance sets you apart from other candidates. A study in the <em>Journal of Applied Psychology</em> found that individuals who practiced with hints exhibited greater self-efficacy and problem-solving abilities in high-pressure situations (Bandura, 1997).</p>

<h2>Conclusion</h2>

<p>Preparing for interviews is as much about developing problem-solving skills as it is about mastering specific answers. Hint-based interview prep offers a balanced approach that fosters deep learning, reduces frustration, and builds the confidence needed to excel in high-pressure environments. By shifting from answer-centric methods to hint-guided strategies, you can transform your study sessions from moments of overwhelm to opportunities for growth and mastery.</p>

<h3>References</h3>

<ul>
    <li>Bandura, A. (1997). <em>Self-efficacy: The exercise of control</em>. New York: W.H. Freeman.</li>
    <li>Freeman, S., Eddy, S. L., McDonough, M., Smith, M. K., Okoroafor, N., Jordt, H., &amp; Wenderoth, M. P. (2014). Active learning increases student performance in science, engineering, and mathematics. <em>Proceedings of the National Academy of Sciences</em>, 111(23), 8410-8415.</li>
    <li>Sweller, J. (2011). Cognitive load theory. <em>Psychology of Learning and Motivation</em>, 55, 37-76.</li>
    <li>VanLehn, K. (2011). The relative effectiveness of human tutoring, intelligent tutoring systems, and other tutoring systems. <em>Educational Psychologist</em>, 46(4), 197-221.</li>
</ul>

        `,
        category: { title: 'Interview Prep', href: '#' },
    },
    // Add more posts as needed...
];

const BlogPost = () => {
    const { postId } = useParams(); // Extract postId from the URL
    const [post, setPost] = useState<Post | null>(null); // Post can be either a Post object or null
    const [user, setUser] = useState<User | null>(null);
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((authUser) => {
            if (authUser) {
                setUser(authUser);
            } else {
                console.log("User is signed out");
                setUser(null);
            }
        });

        return () => unsubscribe();
    }, []);
    useEffect(() => {
        if (postId) {
            const foundPost = posts.find((p) => p.id === parseInt(postId, 10)); // Convert postId to number
            setPost(foundPost || null); // Set the post or null if not found
        }
    }, [postId]);

    if (!post) {
        return <div>Loading or post not found...</div>; // Handle loading or post not found
    }

    const MarkdownViewer = ({ markdownContent }: { markdownContent: string }) => {
        return <ReactMarkdown
            children={markdownContent}
            remarkPlugins={[]}
            rehypePlugins={[]}
        />
    };

    return (
        <div className="bg-white">
            <header className="sticky top-0 z-50 max-w-8xl border border-gray-300 bg-white rounded-2xl mt-2 mx-auto sm:mx-2 lg:mx-4">
                    {/* Navbar */}
                    <nav className="flex items-center justify-between h-16 px-2 lg:px-4" aria-label="Global">
                        {/* Logo */}
                        <div className="flex">
                            <a href="/" className="-m-1">
                                <span className="sr-only">HinterviewGPT</span>
                                <img className="h-8 w-auto" src={logoLato} alt="HinterviewGPT Logo" />
                            </a>
                        </div>

                        {/* Navigation Links */}
                        <div className="hidden lg:flex lg:gap-x-6 ml-auto">
                            <a href="/#how-it-works" className="text-md font-medium text-gray-700 hover:text-indigo-600 transition">
                                How It Works
                            </a>
                            <a href="/#pricing" className="text-md font-medium text-gray-700 hover:text-indigo-600 transition">
                                Pricing
                            </a>
                            <a href="/#faq" className="text-md font-medium text-gray-700 hover:text-indigo-600 transition">
                                FAQ
                            </a>
                            <a href="/blog" className="text-md font-medium text-gray-700 hover:text-indigo-600 transition">
                                Blog
                            </a>
                        </div>

                        {/* Log in / App Button */}
                        <div className="hidden lg:flex lg:justify-end ml-4">
                            <a
                                href={user ? "/activity" : "/auth"}
                                className="inline-flex items-center px-3 py-1.5 border border-transparent text-sm font-semibold rounded-md text-white bg-indigo-600 hover:bg-indigo-700 transition"
                            >
                                {user ? "App" : "Log In"} <span aria-hidden="true">→</span>
                            </a>
                        </div>

                        {/* Mobile Menu Button */}
                        <div className="flex lg:hidden">
                            <button
                                type="button"
                                className="inline-flex items-center justify-center p-1.5 rounded-md text-gray-700 hover:text-indigo-600 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                                onClick={() => setMobileMenuOpen(true)}
                            >
                                <span className="sr-only">Open main menu</span>
                                {/* Icon: Hamburger Menu */}
                                {/* <MenuIcon className="h-6 w-6" aria-hidden="true" /> */}
                            </button>
                        </div>
                    </nav>
                </header>

            <main className="flex-grow p-4 lg:px-28 flex flex-col min-h-screen">
                <article className="mx-auto max-w-4xl blog-post-container">
                    {/* Post header */}
                    <div className="text-center">
                        <h1 className="mt-4 text-4xl font-extrabold text-gray-900">
                            {post.title}
                        </h1>
                        <time dateTime={post.datetime} className="text-gray-500 text-sm mt-2 block">
                            {post.date}
                        </time>
                    </div>

                   

                    {/* Post content */}
                    <div className="mt-8">
                        <div
                            dangerouslySetInnerHTML={{ __html: post.content }}
                        />
                    </div>
                </article>
            </main>
        </div>
    );
};

export default BlogPost;
